import { CalculationReceivingOutput } from "@/common/api/calculationWebsocket/calculationReceivingOutput/calculationReceivingOutput";
import { CalculationReceivingOutputState } from "@/common/api/calculationWebsocket/calculationReceivingOutput/calculationReceivingOutputState";
import { EndOfOutputState } from "@/common/api/calculationWebsocket/calculationReceivingOutput/endOfOutputState";

export class ReceivingDataState extends CalculationReceivingOutputState {
  private static WS_OUTPUT_START_BOUNDARY = "WS_OUTPUT_START";
  private static WS_OUTPUT_CONTINUE_BOUNDARY = "WS_OUTPUT_CONTINUE";
  private static WS_OUTPUT_END_BOUNDARY = "WS_OUTPUT_END";

  private isStarted = false;

  constructor(private calculationReceivingOutput: CalculationReceivingOutput) {
    super();
  }

  public async processMessage(message: string) {
    if (message === ReceivingDataState.WS_OUTPUT_END_BOUNDARY) {
      this.calculationReceivingOutput.changeState(new EndOfOutputState());
      return { isEnd: true };
    }

    try {
      const data = JSON.parse(message);

      return { data, isEnd: false };
    } catch (err) {
      throw new Error("The incoming data should be a valid JSON.");
    }
  }

  public continue() {
    if (this.isStarted) {
      this.calculationReceivingOutput.calculationWebsocket.emit(ReceivingDataState.WS_OUTPUT_CONTINUE_BOUNDARY);
    } else {
      this.calculationReceivingOutput.calculationWebsocket.emit(ReceivingDataState.WS_OUTPUT_START_BOUNDARY);
      this.isStarted = true;
    }
  }
}
