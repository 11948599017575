import { v7 as uuidv7 } from "uuid";

export async function requestDialogParent<Res extends Omit<object, "id">, Req extends object = object>(
  requestData: Req extends { id: unknown } ? never : Req
): Promise<Res> {
  if ("id" in requestData) {
    throw new Error("id should not be in the parameters.");
  }

  return new Promise((resolve) => {
    const id = uuidv7();

    Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, (result) => {
      const message = JSON.parse(result.message);
      if (id === message.id) {
        resolve(message.data);
      }
    });

    Office.context.ui.messageParent(JSON.stringify({ id, ...requestData }));
  });
}
