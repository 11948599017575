import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";

export interface DeleteIpEntryConfirmDialogProps {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}
export function DeleteIpEntryConfirmDialog(props: DeleteIpEntryConfirmDialogProps) {
  const { onCancel, onOk, open } = props;

  return (
    <Dialog open={open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{"Confirm delete IP entry?" /* TODO: Localization */}</DialogTitle>
          <DialogContent>
            {
              "This action might cause some user(s) to lose access to the application, proceed?" /* TODO: Localization */
            }
          </DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={onOk}>
              {"OK" /* TODO: Localization */}
            </Button>
            <Button appearance="secondary" onClick={onCancel}>
              {"Cancel" /* TODO: Localization */}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
