import * as React from "react";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import {
  Button,
  Input,
  Label,
  Link,
  Spinner,
  Text,
  makeStyles,
  mergeClasses,
  tokens,
} from "@fluentui/react-components";
import Countdown, { zeroPad } from "react-countdown";

export interface OTPFormProps {
  onBack: () => void;
  onVerifySuccess: () => void;
}
export default function OTPForm(props: OTPFormProps) {
  const { onBack, onVerifySuccess } = props;

  const styles = useStyles();

  const getTwoMinutesFromNowUnixTimestamp = () => {
    const twoMinutes = 1000 * 60 * 2;
    return Date.now() + twoMinutes;
  };

  const [otpInputArray, setOTPInputArray] = React.useState(["", "", "", "", "", ""]);
  const [isOTPExpired, setIsOTPExpired] = React.useState(false);
  const [otpExpiryDate, setOTPExpiryDate] = React.useState(getTwoMinutesFromNowUnixTimestamp());
  const [isResendOTPButtonDisabled, setIsResendOTPButtonDisabled] = React.useState(true);
  const [isVerifying, setIsVerifying] = React.useState(false);

  const isVerifyButtonDisabled = React.useMemo(
    () =>
      otpInputArray.some((otpValue) => {
        const parsedOTPValue = Number.parseInt(otpValue, 10);
        return Number.isNaN(parsedOTPValue);
      }),
    [otpInputArray]
  );

  React.useEffect(() => {
    if (isVerifying) {
      const twoSeconds = 1000 * 2;
      const timeout = window.setTimeout(() => {
        onVerifySuccess();
      }, twoSeconds);

      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [isVerifying, onVerifySuccess]);

  React.useEffect(() => {
    if (isResendOTPButtonDisabled) {
      const tenSeconds = 1000 * 10;
      const timeout = window.setTimeout(() => {
        setIsResendOTPButtonDisabled(false);
      }, tenSeconds);

      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [isResendOTPButtonDisabled]);

  return (
    <div className={styles.background}>
      <div className={styles.headerContainer}>
        <Button className={styles.backButton} appearance="transparent" icon={<ArrowLeftRegular />} onClick={onBack} />
        <Label className={styles.headerLabel}>{"Enter verification code" /* TODO: Localization */}</Label>
      </div>
      <div className={styles.subHeaderContainer}>
        <div>
          <Text className={styles.subHeaderLabel}>
            {"Please enter the 6 digit code that was sent to your email address" /* TODO: Localization */}
          </Text>
        </div>
        <div className={styles.otpExpiryContainer}>
          {isOTPExpired ? (
            <>
              <Text className={styles.subHeaderLabel}>{"The code has" /* TODO: Localization */}</Text>{" "}
              <Text className={styles.expiredText}>{"expired" /* TODO: Localization */}</Text>
            </>
          ) : (
            <Countdown
              date={otpExpiryDate}
              onComplete={() => setIsOTPExpired(true)}
              renderer={({ minutes, seconds, total }) => {
                const totalInSeconds = total / 1000;

                let expiryCountdownTextClassName;
                if (totalInSeconds <= 3) {
                  expiryCountdownTextClassName = styles.otpExpiryCountdownDangerText;
                } else if (totalInSeconds > 3 && totalInSeconds < 10) {
                  expiryCountdownTextClassName = styles.otpExpiryCountdownWarningText;
                } else {
                  expiryCountdownTextClassName = styles.otpExpiryCountdownText;
                }

                return (
                  <div className={styles.otpExpiryContainer}>
                    <Text className={styles.subHeaderLabel}>{"The code will expire in" /* TODO: Localization */}</Text>
                    <Text className={expiryCountdownTextClassName}>{`${minutes}:${zeroPad(seconds)}`}</Text>
                  </div>
                );
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.otpInputContainer}>
        {Array(6)
          .fill("")
          .map((_, index) => {
            return (
              <Input
                key={index}
                className={styles.otpInput}
                value={otpInputArray[index]}
                maxLength={1}
                autoFocus={index === 0}
                onKeyDown={(event) => {
                  if (!document.activeElement) {
                    return false;
                  }

                  if (event.key === "Backspace") {
                    const newOTPInputArray = otpInputArray.slice();
                    newOTPInputArray[index] = "";
                    setOTPInputArray(newOTPInputArray);

                    const previousInput = document.activeElement.parentNode?.previousSibling?.firstChild;
                    if (previousInput) {
                      (previousInput as HTMLElement).focus();
                    }
                  } else if (!Number.isNaN(Number.parseInt(event.key))) {
                    const newOTPInputArray = otpInputArray.slice();
                    newOTPInputArray[index] = event.key;
                    setOTPInputArray(newOTPInputArray);

                    const nextInput = document.activeElement.parentNode?.nextSibling?.firstChild;
                    if (nextInput) {
                      (nextInput as HTMLElement).focus();
                    } else {
                      (document.activeElement as HTMLElement).blur();
                    }
                    return true;
                  }

                  return false;
                }}
              />
            );
          })}
      </div>
      <div className={styles.resendOTPContainer}>
        <Text className={styles.resendOTPLabel}>
          {"Didn't receive the verification code?" /* TODO: Localization */}
        </Text>
        <Link
          disabled={isResendOTPButtonDisabled}
          onClick={() => {
            setIsResendOTPButtonDisabled(true);
            setIsOTPExpired(false);
            setOTPExpiryDate(getTwoMinutesFromNowUnixTimestamp());
          }}
        >
          {"Resend" /* TODO: Localization */}
        </Link>
      </div>
      <div>
        <Button
          className={mergeClasses(styles.verifyButton, isVerifying && styles.verifyButtonLoading)}
          icon={isVerifying ? <Spinner size={"tiny"} /> : <></>}
          disabled={isVerifyButtonDisabled}
          appearance="primary"
          onClick={() => {
            setIsVerifying(true);
          }}
        >
          <Text className={styles.verifyButtonText}>{"Verify" /* TODO: Localization */}</Text>
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  backButton: {
    left: "0px",
    padding: "0px",
    position: "absolute",
  },
  background: {
    background: tokens.colorNeutralForegroundInverted,
    borderRadius: tokens.borderRadiusXLarge,
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
    display: "flex",
    flexDirection: "column",
    height: "300px",
    justifyContent: "center",
    padding: tokens.spacingVerticalXXXL,
    rowGap: tokens.spacingVerticalL,
    width: "500px",
  },
  expiredText: {
    color: tokens.colorStatusDangerBackground3,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
  },
  headerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  headerLabel: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
  },
  otpExpiryContainer: {
    alignItems: "center",
    display: "flex",
    gap: tokens.spacingHorizontalXS,
    justifyContent: "center",
  },
  otpExpiryCountdownDangerText: {
    color: tokens.colorStatusDangerBackground3,
  },
  otpExpiryCountdownText: {
    color: tokens.colorBrandStroke1,
  },
  otpExpiryCountdownWarningText: {
    color: tokens.colorPaletteYellowBackground3,
  },

  otpInput: {
    "> input": {
      fontSize: tokens.fontSizeHero900,
      textAlign: "center",
    },
    height: "64px",
    width: "64px",
  },
  otpInputContainer: {
    alignItems: "center",
    display: "flex",
    gap: tokens.spacingHorizontalM,
    justifyContent: "center",
  },
  resendOTPContainer: {
    alignItems: "center",
    display: "flex",
    gap: tokens.spacingHorizontalXS,
    justifyContent: "center",
  },
  resendOTPLabel: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
  },
  subHeaderContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  subHeaderLabel: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
  },
  verifyButton: {
    width: "100%",
  },
  verifyButtonLoading: {
    ":hover": {
      backgroundColor: tokens.colorBrandBackground,
      cursor: "not-allowed",
    },
    opacity: 0.7,
  },
  verifyButtonText: {
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    textTransform: "uppercase",
  },
});
