import * as React from "react";
import { RouteProps } from "react-router-dom";
import Login from "..";

const ROUTES: RouteProps[] = [
  {
    element: <Login />,
    path: "/login",
  },
];

export default ROUTES;
