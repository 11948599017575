import * as React from "react";
import { keyframes } from "@fluentui/merge-styles";
import { makeStyles } from "@fluentui/react-components";
import Chat from "@/ui/features/copilot/components/Chat";

const backgroundFadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const useStyles = makeStyles({
  background: {
    animation: `${backgroundFadeIn} 1.5s`,
    height: "100vh",
    width: "100vw",
  },
});

export default function FRMCopilot() {
  const styles = useStyles();

  return (
    <div className={styles.background}>
      <Chat />
    </div>
  );
}
