export const ECalculationType = {
  IFRS9: "IFRS9",
  IRR: "IRR",
  IRRBB: "IRRBB",
} as const;

export const EDocumentSettings = {
  CALCULATION_TYPE: "calculationType",
  TOKEN: "token",
} as const;

export const ECommonCommandMessageType = {
  CLOSE_DIALOG: "CLOSE_DIALOG",
} as const;

export const ECalculateMessageType = {
  GET_INPUT_DATA: "GET_INPUT_DATA",
  POPULATE_OUTPUT_DATA: "POPULATE_OUTPUT_DATA",
} as const;

export const ESynchronizeMessageType = {
  ADD_WORKSHEET: "ADD_WORKSHEET",
  CONFIRM_SYNCHRONIZATION: "CONFIRM_SYNCHRONIZATION",
  GET_WORKSHEET_NAMES: "GET_WORKSHEET_NAMES",
  REPLACE_WORKSHEET: "REPLACE_WORKSHEET",
} as const;

export const EValidateMessageType = {
  JUMP_TO_CELL: "JUMP_TO_CELL",
} as const;

export const ECellValidationErrorType = {
  DUPLICATE_HEADER: "DUPLICATE_HEADER",
  INCORRECT_HEADER: "INCORRECT_HEADER",
  INCORRECT_NO_OF_HEADERS: "INCORRECT_NO_OF_HEADERS",
  MISSING_WORKSHEET: "MISSING_WORKSHEET",

  WRONG_DATA_TYPE_BOOLEAN: "WRONG_DATA_TYPE_BOOLEAN",
  WRONG_DATA_TYPE_DATE: "WRONG_DATA_TYPE_DATE",
  WRONG_DATA_TYPE_INTEGER: "WRONG_DATA_TYPE_INTEGER",
  WRONG_DATA_TYPE_NUMBER: "WRONG_DATA_TYPE_NUMBER",
  WRONG_DATA_TYPE_STRING: "WRONG_DATA_TYPE_STRING",
  WRONG_DATA_TYPE_YEAR_MONTH: "WRONG_DATA_TYPE_YEAR_MONTH",
};

export const ECellType = {
  BOOL: "bool",
  DATE: "date",
  DOUBLE: "double",
  INTEGER: "integer",
  STRING: "string",
} as const;

export const ETableOrientation = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
} as const;

export const ESynchronizingAction = {
  ADD: "ADD",
  REPLACE: "REPLACE",
} as const;

export const WORKSHEET_MAXIMUM_ROW_COUNT = 1048576;

export const WORKSHEET_MAXIMUM_COL_COUNT = 16384;

export const ENegativeNumberSymbol = {
  BRACKETS: "brackets",
  MINUS: "minus",
} as const;

export const EWorksheetType = {
  INPUT: "INPUT",
  OUTPUT: "OUTPUT",
} as const;
