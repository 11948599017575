import * as React from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardPreview,
  Text,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { KeyRegular } from "@fluentui/react-icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export interface RSAPublicKey {
  id: string;
  alias: string;
  fingerprint: string;
  enabled: boolean;
  created: string;
}

export interface RSAKeyListProps {
  keys: RSAPublicKey[];
  onDelete: (key: RSAPublicKey) => void;
}

export default function RSAKeyList(props: RSAKeyListProps) {
  const { keys, onDelete } = props;

  const styles = useStyles();

  return keys.map((key) => {
    const { alias, created, enabled, fingerprint } = key;

    return (
      <Card key={`${alias}_${created}`} className={styles.card} orientation="horizontal">
        <CardPreview className={styles.cardPreview}>
          <div className={styles.cardPreviewInnerContainer}>
            <Badge
              className={styles.enabledBadge}
              appearance="filled"
              color={enabled ? "success" : "danger"}
              size={"large"}
            />
            <KeyRegular fontSize={42} />
          </div>
        </CardPreview>
        <CardHeader
          header={<Text weight="semibold">{alias}</Text>}
          description={
            <div>
              <div>
                <Text truncate className={styles.fingerprintCaption}>
                  {fingerprint}
                </Text>
              </div>
              <div>
                <Text truncate className={styles.addedTimeAgoCaption}>
                  {`Added ${dayjs(created).fromNow()}` /* TODO: Localization */}
                </Text>
              </div>
            </div>
          }
          action={
            <Button
              className={styles.deleteButton}
              appearance="outline"
              onClick={(event) => {
                onDelete(key);
                event.stopPropagation();
              }}
            >
              {"Delete" /* TODO: Localization */}
            </Button>
          }
        />
      </Card>
    );
  });
}

const useStyles = makeStyles({
  addedTimeAgoCaption: {
    color: tokens.colorNeutralForeground3,
  },
  card: {
    borderRadius: tokens.borderRadiusXLarge,
    overflow: "unset",
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
    ...shorthands.borderWidth("1px"),
    ":hover": {
      backgroundColor: tokens.colorSubtleBackgroundHover,
    },
  },
  cardPreview: {
    paddingBottom: tokens.spacingHorizontalM,
    paddingLeft: tokens.spacingHorizontalM,
    paddingTop: tokens.spacingHorizontalM,
  },
  cardPreviewInnerContainer: {
    alignItems: "center",
    columnGap: tokens.spacingHorizontalM,
    display: "flex !important",
  },
  deleteButton: {
    ":hover": {
      backgroundColor: tokens.colorStatusDangerBackground3Hover,
      color: tokens.colorNeutralForegroundOnBrand,
    },
    ":hover:active": {
      backgroundColor: tokens.colorStatusDangerBackground3Pressed,
      color: tokens.colorNeutralForegroundOnBrand,
    },
    backgroundColor: tokens.colorStatusDangerBackground3,
    color: tokens.colorNeutralForegroundOnBrand,
  },
  enabledBadge: {
    height: "12px",
    width: "12px",
  },
  fingerprintCaption: {
    color: tokens.colorNeutralStroke1,
  },
});
