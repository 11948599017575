import * as React from "react";
import { AddRegular, ArrowLeftRegular } from "@fluentui/react-icons";
import {
  Button,
  Divider,
  Image,
  Label,
  SearchBox,
  Text,
  Tooltip,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { Connection } from "@/ui/features/login/types";
import { EnvironmentManager } from "@/common/utils/environmentManager";
import ConnectionForm from "@/ui/features/login/components/ConnectionForm";
import ConnectionList from "@/ui/features/login/components/ConnectionList";
import NoConnections from "@/ui/features/login/components/NoConnections";
import naturalCompare from "natural-compare-lite";

export interface ManageConnectionsProps {
  connections: Connection[];
  onConnectionsChange: (connections: Connection[]) => void;
  onBack: () => void;
}

export default function ManageConnections(props: ManageConnectionsProps) {
  const { connections, onBack, onConnectionsChange } = props;

  const styles = useStyles();

  const [isConnectionFormVisible, setIsConnectionFormVisible] = React.useState(false);
  const [selectedConnection, setSelectedConnection] = React.useState<Connection | null>(null);
  const [searchInput, setSearchInput] = React.useState("");

  const filteredAndSortedConnections = React.useMemo(() => {
    const lowerCasedSearchInput = searchInput.toLowerCase();
    return connections
      .filter(({ alias, url }) => {
        return (
          !searchInput ||
          url.toLowerCase().includes(lowerCasedSearchInput) ||
          alias.toLowerCase().includes(lowerCasedSearchInput)
        );
      })
      .sort((a, b) => naturalCompare(a.alias, b.alias));
  }, [connections, searchInput]);

  const isAddConnectionButtonDisabled = connections.length >= 5;
  const addConnectionButton = (
    <Button
      appearance="primary"
      icon={<AddRegular />}
      disabled={isAddConnectionButtonDisabled}
      onClick={() => {
        setSelectedConnection(null);
        setIsConnectionFormVisible(true);
      }}
    >
      {"Add Connection" /* TODO: Localization */}
    </Button>
  );

  return (
    <div className={styles.background}>
      <div className={styles.leftContainer}>
        <Button className={styles.backButton} appearance="transparent" icon={<ArrowLeftRegular />} onClick={onBack} />
        <div className={styles.headerContainer}>
          <Label className={styles.headerLabel}>{"Manage connections" /* TODO: Localization */}</Label>
        </div>
        <div className={styles.actionsContainer}>
          {isAddConnectionButtonDisabled ? (
            <Tooltip
              content={
                <Text className={styles.addConnectionButtonTooltipContentText}>{
                  `You cannot add more than ${connections.length} connections.` /* TODO: Localization */
                }</Text>
              }
              relationship="label"
            >
              {addConnectionButton}
            </Tooltip>
          ) : (
            addConnectionButton
          )}
          <SearchBox
            className={styles.searchBox}
            placeholder={"Search" /* TODO: Localization */}
            value={searchInput}
            onChange={(_, data) => setSearchInput(data.value)}
          />
        </div>
        <Divider appearance="subtle">{"Saved connections" /* TODO: Localization */}</Divider>
        <div className={styles.savedConnectionsContainer}>
          {connections.length === 0 ? (
            <NoConnections />
          ) : filteredAndSortedConnections.length > 0 ? (
            <ConnectionList
              connections={filteredAndSortedConnections}
              onCardClick={(connection) => {
                setSelectedConnection(connection);
                setIsConnectionFormVisible(true);
              }}
              onDelete={(connectionToDelete) => {
                if (selectedConnection === connectionToDelete) {
                  setSelectedConnection(null);
                  setIsConnectionFormVisible(false);
                }
                onConnectionsChange(connections.filter((connection) => connection !== connectionToDelete));
              }}
            />
          ) : (
            <div className={styles.noResultsFoundContainer}>
              <Image
                className={styles.noResultsFoundImage}
                src={`${EnvironmentManager.getVariable("baseUrl")}/images/search-96.png`}
              />
              <Text className={styles.noResultsFoundTitle}>{"No results found" /* TODO: Localization */}</Text>
              <Text className={styles.noResultsFoundSubtitle}>{
                `There are no results matching your search criteria` /* TODO: Localization */
              }</Text>
            </div>
          )}
        </div>
      </div>
      {isConnectionFormVisible && (
        <>
          <Divider vertical />
          <div className={styles.rightContainer}>
            <ConnectionForm
              connections={connections}
              connection={selectedConnection}
              onSave={(newConnection) => {
                if (selectedConnection) {
                  onConnectionsChange([
                    ...connections.filter((connection) => connection !== selectedConnection),
                    newConnection,
                  ]);
                } else {
                  onConnectionsChange([...connections, newConnection]);
                }
                setSelectedConnection(null);
                setIsConnectionFormVisible(false);
              }}
              onCancel={() => {
                setSelectedConnection(null);
                setIsConnectionFormVisible(false);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  actionsContainer: {
    alignItems: "center",
    columnGap: tokens.spacingHorizontalL,
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  addConnectionButtonTooltipContentText: {
    maxWidth: "300px",
  },
  backButton: {
    padding: "0px",
    position: "absolute",
  },
  background: {
    background: tokens.colorNeutralForegroundInverted,
    borderRadius: tokens.borderRadiusXLarge,
    display: "flex",
  },
  headerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  headerLabel: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: tokens.spacingVerticalXXXL,
    position: "relative",
    rowGap: tokens.spacingVerticalL,
  },
  noResultsFoundContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalM,
    justifyContent: "center",
  },
  noResultsFoundImage: {
    height: "48px",
    width: "48px",
  },
  noResultsFoundSubtitle: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
    textAlign: "center",
  },
  noResultsFoundTitle: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
    textAlign: "center",
  },
  rightContainer: {
    padding: tokens.spacingVerticalXXXL,
  },
  savedConnectionsContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "250px",
    overflow: "auto",
    rowGap: tokens.spacingVerticalL,
    width: "100%",
  },
  searchBox: {
    maxWidth: "200px",
  },
});
