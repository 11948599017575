import { Button } from "@fluentui/react-components";
import { generateKeyPair, getPrivateKeyWithBoundary, getPublicKeyWithBoundary } from "@/common/utils/crypto";
import React from "react";

export interface GenerateKeyPairButton {
  keyLength: number;
  onGenerate: (keyPair: { publicKey: string; privateKey: string; timeTakenMs: number }) => void;
}
export default function GenerateKeyPairButton(props: GenerateKeyPairButton) {
  const { keyLength, onGenerate } = props;

  return (
    <Button
      appearance="primary"
      onClick={() => {
        const startTime = performance.now();

        const { privateKey, publicKey } = generateKeyPair(keyLength);
        const publicKeyWithBoundary = getPublicKeyWithBoundary(publicKey);
        const privateKeyWithBoundary = getPrivateKeyWithBoundary(privateKey);

        const timeTakenMs = Math.round(performance.now() - startTime);

        onGenerate({
          privateKey: privateKeyWithBoundary,
          publicKey: publicKeyWithBoundary,
          timeTakenMs,
        });
      }}
    >
      {"Generate Key Pair" /* TODO: Localization */}
    </Button>
  );
}
