import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { ESynchronizeMessageType } from "@/common/constants";
import { SYNCHRONIZE_API_REDUCER_PATH } from "@/ui/features/synchronize/store/synchronize/constants";

export function createGetWorksheetNamesApi(
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    typeof SYNCHRONIZE_API_REDUCER_PATH
  >
) {
  return builder.query<string[], void>({
    queryFn: async () => {
      const worksheetNames = await new Promise<string[]>((resolve) => {
        Office.context.ui.messageParent(JSON.stringify({ type: ESynchronizeMessageType.GET_WORKSHEET_NAMES }));
        Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, (result) => {
          resolve(JSON.parse(result.message));
        });
      });
      return {
        data: worksheetNames,
      };
    },
  });
}
