import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { ECalculationType } from "@/common/constants";
import { SYNCHRONIZE_API_REDUCER_PATH } from "@/ui/features/synchronize/store/synchronize/constants";

export function createGetTemplateTypesApi(
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    typeof SYNCHRONIZE_API_REDUCER_PATH
  >
) {
  return builder.query<ObjectValues<typeof ECalculationType>[], void>({
    queryFn: () => {
      return { data: Object.values(ECalculationType) as ObjectValues<typeof ECalculationType>[] };
    },
  });
}
