import { ArrowLeftRegular, DismissRegular } from "@fluentui/react-icons";
import {
  Button,
  Field,
  Input,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarIntent,
  MessageBarTitle,
  Spinner,
  Text,
  makeStyles,
  mergeClasses,
  tokens,
} from "@fluentui/react-components";
import { validatePublicKeyPem } from "@/common/utils/crypto";
import GenerateKeyPairButton from "@/ui/features/manage/components/GenerateKeyPairButton";
import KeyLengthDropdownField from "@/ui/features/manage/components/KeyLengthDropdownField";
import PublicPrivateKeyPair from "@/ui/features/manage/components/PublicPrivateKeyPair";
import React from "react";

export interface AddRSAKeyProps {
  onBack: () => void;
  onAddKeySuccess: (newPublicKey: { alias: string; publicKeyPem: string }) => void;
}

export default function AddRSAKey(props: AddRSAKeyProps) {
  const { onAddKeySuccess, onBack } = props;

  const styles = useStyles();

  const [publicKey, setPublicKey] = React.useState("");
  const [privateKey, setPrivateKey] = React.useState("");
  const [alias, setAlias] = React.useState("");
  const [keyLength, setKeyLength] = React.useState(KeyLengthDropdownField.DEFAULT_KEY_LENGTH);
  const [timeTakenMs, setTimeTakenMs] = React.useState<number | null>(null);
  const [messageBar, setMessageBar] = React.useState<{ description: string; intent: MessageBarIntent; title: string }>({
    description: "",
    intent: "error",
    title: "",
  });
  // TODO: Replace with real API call to add public key
  const [isAdding, setIsAdding] = React.useState(false);

  const dismissMessageBar = () => {
    setMessageBar({
      description: "",
      intent: "error",
      title: "",
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Button className={styles.backButton} appearance="transparent" icon={<ArrowLeftRegular />} onClick={onBack} />
        <Text className={styles.title}>{"Add RSA Key" /* TODO: Localization */}</Text>
        <Text className={styles.subtitle}>{"Generate or upload your public key" /* TODO: Localization */}</Text>
      </div>
      {messageBar.title && (
        <MessageBar intent={messageBar.intent}>
          <MessageBarBody>
            <MessageBarTitle>{messageBar.title}</MessageBarTitle>
            <Text>{messageBar.description}</Text>
          </MessageBarBody>
          <MessageBarActions
            containerAction={
              <Button
                aria-label="dismiss"
                appearance="transparent"
                icon={<DismissRegular />}
                onClick={dismissMessageBar}
              />
            }
          />
        </MessageBar>
      )}
      <div className={styles.actionsContainer}>
        <Field label={"Alias" /* TODO: Localization */} validationState="none" required>
          <Input onChange={(_, { value }) => setAlias(value)} />
        </Field>
        <div className={styles.keyLengthContainer}>
          <KeyLengthDropdownField keyLength={keyLength} onChange={setKeyLength} />
          <GenerateKeyPairButton
            keyLength={keyLength}
            onGenerate={({ privateKey, publicKey, timeTakenMs }) => {
              dismissMessageBar();
              setPublicKey(publicKey);
              setPrivateKey(privateKey);
              setTimeTakenMs(timeTakenMs);
            }}
          />
          {timeTakenMs !== null && <Text>{`Generated in ${timeTakenMs}ms` /* TODO: Localization */}</Text>}
        </div>
      </div>

      <div className={styles.generateRSAKeyContainer}>
        <PublicPrivateKeyPair
          publicKey={publicKey}
          privateKey={privateKey}
          onUpload={async (file) => {
            const fourKilobytes = 4096;
            if (file.size > fourKilobytes) {
              setMessageBar({
                description: "The file size cannot be larger than 4KB.", // TODO: Localization
                intent: "error",
                title: "Upload failed", // TODO: Localization
              });
              return;
            }

            const publicKeyPem = await file.text();
            const isValidPublicKeyPem = validatePublicKeyPem(publicKeyPem);
            if (!isValidPublicKeyPem) {
              setMessageBar({
                description: "The file is not a valid RSA public key.", // TODO: Localization
                intent: "error",
                title: "Upload failed", // TODO: Localization
              });
              return;
            }

            dismissMessageBar();
            setPublicKey(publicKeyPem);
            setPrivateKey("");
          }}
        />
      </div>
      <div>
        <Button
          className={mergeClasses(isAdding && styles.addButtonLoading)}
          appearance="primary"
          disabled={!publicKey || !alias || isAdding}
          onClick={() => {
            // TODO: Replace with real API call to add public key
            setIsAdding(true);
            setTimeout(() => {
              setIsAdding(false);
              onAddKeySuccess({ alias, publicKeyPem: publicKey });
            }, 2000);
          }}
          {...(isAdding ? { icon: <Spinner size={"tiny"} /> } : {})}
        >
          {"Add" /* TODO: Localization */}
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",
    rowGap: tokens.spacingVerticalL,
  },
  addButtonLoading: {
    ":hover": {
      backgroundColor: tokens.colorBrandBackground,
      color: tokens.colorNeutralForegroundOnBrand,
      cursor: "not-allowed",
    },
    ":hover:active": {
      backgroundColor: tokens.colorBrandBackground,
      color: tokens.colorNeutralForegroundOnBrand,
      cursor: "not-allowed",
    },
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
    opacity: 0.7,
  },
  addTypeRadioGroup: {
    marginLeft: "-8px",
  },
  backButton: {
    minWidth: "unset",
    padding: "0px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
    padding: tokens.spacingVerticalXXXL,
    rowGap: tokens.spacingVerticalL,
  },
  generateRSAKeyContainer: {
    flexGrow: 1,
  },
  keyLengthContainer: {
    alignItems: "end",
    columnGap: tokens.spacingHorizontalM,
    display: "flex",
  },
  subtitle: {
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
    textAlign: "center",
  },
  title: {
    flexShrink: 0,
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
    textAlign: "center",
  },
  titleContainer: {
    alignItems: "baseline",
    display: "flex",
    gap: tokens.spacingHorizontalS,
    position: "relative",
  },
});
