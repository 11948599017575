import * as React from "react";
import { keyframes } from "@fluentui/merge-styles";
import { makeStyles } from "@fluentui/react-components";
import ValidateMain from "@/ui/features/validate/components/ValidateMain";

const backgroundFadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const useStyles = makeStyles({
  background: {
    animation: `${backgroundFadeIn} 1.5s`,
    height: "100vh",
    width: "100vw",
  },
});

export default function Synchronize() {
  const styles = useStyles();

  return (
    <div className={styles.background}>
      <ValidateMain />
    </div>
  );
}
