import { Button, Field, Input, Label, makeStyles, tokens } from "@fluentui/react-components";
import { Connection } from "@/ui/features/login/types";
import React from "react";

export interface ConnectionFormProps {
  connections: Connection[];
  connection: Connection | null;
  onSave: (connection: Connection) => void;
  onCancel: () => void;
}

export default function ConnectionForm(props: ConnectionFormProps) {
  const { connection, connections, onCancel, onSave } = props;

  const styles = useStyles();

  const [alias, setAlias] = React.useState(connection?.alias ?? "");
  const [url, setUrl] = React.useState(connection?.url ?? "");

  React.useEffect(() => {
    setAlias(connection?.alias ?? "");
    setUrl(connection?.url ?? "");
  }, [connection?.alias, connection?.url]);

  const aliasValidation = React.useMemo(() => {
    if (!alias) {
      return {
        validationMessage: "Alias is required",
        validationState: "error" as const, // TODO: Localization
      };
    }
    const existingConnectionWithSameAlias = connections.find(
      (existingConnection) => connection !== existingConnection && existingConnection.alias === alias
    );
    if (existingConnectionWithSameAlias) {
      return {
        validationMessage: "Connection with same alias exists",
        validationState: "error" as const, // TODO: Localization
      };
    } else {
      return {
        validationState: "none" as const,
      };
    }
  }, [alias, connection, connections]);

  const urlValidation = React.useMemo(() => {
    if (!url) {
      return {
        validationMessage: "URL is required",
        validationState: "error" as const, // TODO: Localization
      };
    }
    const existingConnectionWithSameUrl = connections.find(
      (existingConnection) => connection !== existingConnection && existingConnection.url === url
    );
    if (existingConnectionWithSameUrl) {
      return {
        validationMessage: "Connection with same URL exists",
        validationState: "error" as const, // TODO: Localization
      };
    } else {
      return {
        validationState: "none" as const,
      };
    }
  }, [connections, connection, url]);

  const isSaveDisabled = React.useMemo(() => {
    const isSameAsCurrentConnection = connection?.alias === alias && connection?.url === url;
    const doesFormHaveErrors = aliasValidation.validationState === "error" || urlValidation.validationState === "error";

    return isSameAsCurrentConnection || doesFormHaveErrors;
  }, [connection?.alias, connection?.url, alias, url, aliasValidation.validationState, urlValidation.validationState]);

  return (
    <div className={styles.container}>
      <Field
        label={
          <Label className={styles.aliasFieldLabel} required>
            {"Alias" /* TODO: Localization */}
          </Label>
        }
        validationState={aliasValidation.validationState}
        validationMessage={aliasValidation.validationMessage}
      >
        <Input
          autoFocus
          placeholder={"Enter alias" /* TODO: Localization */}
          value={alias}
          onChange={(_, data) => setAlias(data.value)}
        />
      </Field>
      <Field
        label={
          <Label className={styles.urlFieldLabel} required>
            {"URL" /* TODO: Localization */}
          </Label>
        }
        validationState={urlValidation.validationState}
        validationMessage={urlValidation.validationMessage}
      >
        <Input
          placeholder={"Enter URL" /* TODO: Localization */}
          value={url}
          onChange={(_, data) => setUrl(data.value)}
        />
      </Field>
      <div className={styles.actionsContainer}>
        <Button appearance="primary" disabled={isSaveDisabled} onClick={() => onSave({ alias, url })}>
          {"Save" /* TODO: Localization */}
        </Button>
        <Button appearance="secondary" onClick={onCancel}>
          {"Cancel" /* TODO: Localization */}
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  actionsContainer: {
    alignItems: "flex-end",
    columnGap: tokens.spacingHorizontalM,
    display: "flex",
    flexGrow: 1,
  },
  aliasFieldLabel: {
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    textTransform: "uppercase",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  urlFieldLabel: {
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    textTransform: "uppercase",
  },
});
