import { formatPublicKeyFingerprint, getPublicKeyFingerprint } from "@/common/utils/crypto";
import { getCurrentDateTimeUTCString } from "@/common/utils/datetime";
import { v4 as uuidv4 } from "uuid";
import AddRSAKey from "@/ui/features/manage/components/AddRSAKey";
import ManageRSAKeys from "@/ui/features/manage/components/ManageRSAKeys";
import PageView from "@/ui/features/manage";
import React from "react";

export default function Keys() {
  const [view, setView] = React.useState<ObjectValues<typeof EView>>(EView.MANAGE_RSA_KEYS);
  const [keys, setKeys] = React.useState(
    Array(5)
      .fill("")
      .map((_, index) => {
        return {
          alias: `John Doe's Macbook-Pro ${index + 1}`,
          created: "2024-07-17T04:45:00Z",
          enabled: index % 2 === 0 ? true : false,
          fingerprint: "4d:5b:97:19:8c:fe:06:f0:29:e7:f5:96:77:cb:3c:71",
          id: uuidv4(),
        };
      })
  );

  const currentView = React.useMemo(() => {
    if (view === EView.MANAGE_RSA_KEYS) {
      return (
        <ManageRSAKeys
          keys={keys}
          onAddKey={() => {
            setView(EView.ADD_RSA_KEY);
          }}
          onDeleteKey={(keyToDelete) => {
            setKeys((prev) => prev.filter((key) => key.id !== keyToDelete.id));
          }}
        />
      );
    } else if (view === EView.ADD_RSA_KEY) {
      return (
        <AddRSAKey
          onBack={() => {
            setView(EView.MANAGE_RSA_KEYS);
          }}
          onAddKeySuccess={({ alias, publicKeyPem }) => {
            setView(EView.MANAGE_RSA_KEYS);
            setKeys([
              {
                alias,
                created: getCurrentDateTimeUTCString(),
                enabled: true,
                fingerprint: formatPublicKeyFingerprint(getPublicKeyFingerprint(publicKeyPem)),
                id: uuidv4(),
              },
              ...keys,
            ]);
          }}
        />
      );
    }
  }, [keys, view]);

  return <PageView>{currentView}</PageView>;
}

const EView = {
  ADD_RSA_KEY: "ADD_RSA_KEY",
  MANAGE_RSA_KEYS: "MANAGE_RSA_KEYS",
} as const;
