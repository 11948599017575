import { LOGIN_API_REDUCER_PATH } from "@/ui/features/login/store/login/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createLoginApi } from "@/ui/features/login/store/login/apis/login";

export const loginApiSlice = createApi({
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    login: createLoginApi(builder),
  }),
  reducerPath: LOGIN_API_REDUCER_PATH,
});

export const { useLazyLoginQuery } = loginApiSlice;
