import { ECalculationType } from "@/common/constants";
import { EnvironmentManager } from "@/common/utils/environmentManager";
export class CalculationWebsocket {
  private websocket: WebSocket;

  constructor(calculationType: ObjectValues<typeof ECalculationType>) {
    let calculateEndpoint;

    if (calculationType === ECalculationType.IRR) {
      calculateEndpoint = "/api/calculation/IrrCalculations/Calculate";
    } else if (calculationType === ECalculationType.IFRS9) {
      calculateEndpoint = "/api/calculation/Ifrs9Calculations/Calculate";
    } else if (calculationType === ECalculationType.IRRBB) {
      calculateEndpoint = "/api/calculation/IrrbbCalculations/Calculate";
    }

    if (!calculateEndpoint) {
      throw new Error("Invalid calculation type");
    }

    this.websocket = new WebSocket(
      `wss://${new URL(EnvironmentManager.getVariable("backendBaseUrl")).host}${calculateEndpoint}`
    );
  }

  public emit(data: string | ArrayBufferLike | Blob | ArrayBufferView) {
    if (this.isConnected()) {
      this.websocket.send(data);
      return;
    }
    throw new Error("Cannot send data to a closed socket.");
  }

  public addMessageEventListener(listener: (ev: MessageEvent<any>) => void) {
    return this.websocket.addEventListener("message", listener);
  }

  public removeMessageEventListener(listener: (ev: MessageEvent<any>) => void) {
    return this.websocket.removeEventListener("message", listener);
  }

  public isConnected() {
    return this.websocket.readyState === this.websocket.OPEN;
  }

  public close() {
    this.websocket.close();
  }
}
