import * as React from "react";
import { ArrowDownloadRegular, ArrowUploadRegular, CopyRegular } from "@fluentui/react-icons";
import { Button, ButtonProps, Text, Textarea, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { saveBlobAsFile } from "@/common/utils/files";

export interface PublicPrivateKeyPairProps {
  publicKey: string;
  privateKey: string;
  onUpload: (file: File) => void;
}

export default function PublicPrivateKeyPair(props: PublicPrivateKeyPairProps) {
  const { onUpload, privateKey, publicKey } = props;

  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.publicKeyContainer}>
        <div className={styles.commonHeader}>
          <Text size={300}>{"Public key" /* TODO: Localization */}</Text>
          <div className={styles.actionsContainer}>
            <UploadButton onUpload={onUpload} />
            <CopyButton
              disabled={!publicKey}
              onClick={async () => {
                await navigator.clipboard.writeText(publicKey);
              }}
            />
            <DownloadButton
              disabled={!publicKey}
              onClick={() => {
                saveBlobAsFile(new Blob([publicKey]), "public_key.pem");
              }}
            />
          </div>
        </div>
        <div className={styles.commonBody}>
          <Textarea className={styles.commonTextArea} value={publicKey} />
        </div>
      </div>
      <div className={styles.privateKeyContainer}>
        <div className={styles.commonHeader}>
          <Text size={300}>{"Private key" /* TODO: Localization */}</Text>
          <div className={styles.actionsContainer}>
            <CopyButton
              disabled={!privateKey}
              onClick={async () => {
                await navigator.clipboard.writeText(privateKey);
              }}
            />
            <DownloadButton
              disabled={!privateKey}
              onClick={() => {
                saveBlobAsFile(new Blob([privateKey]), "private_key.pem");
              }}
            />
          </div>
        </div>
        <div className={styles.commonBody}>
          <Textarea className={styles.commonTextArea} disabled={!!(publicKey && !privateKey)} value={privateKey} />
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  actionsContainer: {
    alignItems: "center",
    columnGap: tokens.spacingHorizontalM,
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  commonBody: {
    flexGrow: 1,
    padding: tokens.spacingHorizontalXXL,
  },
  commonHeader: {
    alignItems: "center",
    backgroundColor: tokens.colorNeutralBackground3,
    display: "flex",
    padding: tokens.spacingHorizontalM,
    ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStencil1Alpha),
  },
  commonTextArea: {
    "> textarea": {
      maxHeight: "unset",
    },
    height: "100%",
    width: "100%",
  },
  container: { display: "flex", height: "100%", width: "100%" },
  downloadButton: {
    minWidth: "0px",
    padding: "0px",
  },
  privateKeyContainer: {
    borderBottomRightRadius: tokens.borderRadiusMedium,
    borderTopRightRadius: tokens.borderRadiusMedium,
    display: "flex",
    flexDirection: "column",
    width: "50%",
    ...shorthands.border("1px", "solid", tokens.colorNeutralStencil1Alpha),
  },
  publicKeyContainer: {
    borderBottomLeftRadius: tokens.borderRadiusMedium,
    borderTopLeftRadius: tokens.borderRadiusMedium,
    display: "flex",
    flexDirection: "column",
    width: "50%",
    ...shorthands.border("1px", "solid", tokens.colorNeutralStencil1Alpha),
  },
  uploadInput: { display: "none" },
});

type CopyButtonProps = ButtonProps;

function CopyButton(props: CopyButtonProps) {
  const styles = useStyles();

  return (
    <Button className={styles.downloadButton} appearance="transparent" icon={<CopyRegular />} {...props}>
      <Text size={200}>{"Copy" /* TODO: Localization */}</Text>
    </Button>
  );
}

type DownloadButtonProps = ButtonProps;

function DownloadButton(props: DownloadButtonProps) {
  const styles = useStyles();

  return (
    <Button className={styles.downloadButton} appearance="transparent" icon={<ArrowDownloadRegular />} {...props}>
      <Text size={200}>{"Download" /* TODO: Localization */}</Text>
    </Button>
  );
}

type UploadButtonProps = ButtonProps & { onUpload: (file: File) => void };

function UploadButton(props: UploadButtonProps) {
  const { onUpload, ...restProps } = props;

  const styles = useStyles();

  const inputFileRef = React.useRef<HTMLInputElement>(null);

  return (
    <div>
      <input
        ref={inputFileRef}
        type="file"
        title={""}
        className={styles.uploadInput}
        onChange={(event) => {
          const firstFile = event.target.files?.[0];
          if (firstFile) {
            onUpload(firstFile);
            // Clear files to allow same group of files to be uploaded again
            event.target.value = "";
          }
        }}
        accept=".pub,.pem"
      />
      <Button
        className={styles.downloadButton}
        appearance="transparent"
        icon={<ArrowUploadRegular />}
        onClick={() => {
          inputFileRef.current?.click();
        }}
        {...restProps}
      >
        <Text size={200}>{"Upload" /* TODO: Localization */}</Text>
      </Button>
    </div>
  );
}
