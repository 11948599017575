import * as React from "react";
import { RouteProps } from "react-router-dom";
import Synchronize from "..";

const ROUTES: RouteProps[] = [
  {
    element: <Synchronize />,
    path: "/synchronize",
  },
];

export default ROUTES;
