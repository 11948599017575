import { AddRegular } from "@fluentui/react-icons";
import { Button, Text, Tooltip, makeStyles, tokens } from "@fluentui/react-components";
import { DeleteKeyConfirmDialog } from "@/ui/features/manage/components/DeleteKeyConfirmDialog";
import RSAKeyList, { RSAPublicKey } from "@/ui/features/manage/components/RSAKeyList";
import React from "react";

interface ManageRSAKeysProps {
  keys: RSAPublicKey[];
  onAddKey: () => void;
  onDeleteKey: (key: RSAPublicKey) => void;
}
export default function ManageRSAKeys(props: ManageRSAKeysProps) {
  const MAX_KEYS = 50;

  const { keys, onAddKey, onDeleteKey } = props;

  const styles = useStyles();

  const [deleteKeyCandidate, setDeleteKeyCandidate] = React.useState<RSAPublicKey | null>(null);

  const isAddKeyButtonDisabled = keys.length >= MAX_KEYS;
  const addKeyButton = (
    <Button
      className={styles.addKeyButton}
      appearance="primary"
      icon={<AddRegular />}
      disabled={isAddKeyButtonDisabled}
      onClick={onAddKey}
    >
      {"Add Key" /* TODO: Localization */}
    </Button>
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <Text className={styles.title}>{"Manage RSA Keys" /* TODO: Localization */}</Text>
          <Text className={styles.subtitle}>
            {"Configure keys here to allow access to your account from trusted machines" /* TODO: Localization */}
          </Text>
          <div className={styles.actionsContainer}>
            {isAddKeyButtonDisabled ? (
              <Tooltip
                content={<Text>{`You cannot add more than ${keys.length} keys.` /* TODO: Localization */}</Text>}
                positioning={"before"}
                relationship="label"
              >
                {addKeyButton}
              </Tooltip>
            ) : (
              addKeyButton
            )}
          </div>
        </div>
        <RSAKeyList keys={keys} onDelete={setDeleteKeyCandidate} />
      </div>
      <DeleteKeyConfirmDialog
        open={deleteKeyCandidate !== null}
        onOk={() => {
          if (deleteKeyCandidate !== null) {
            setDeleteKeyCandidate(null);
            onDeleteKey(deleteKeyCandidate);
          }
        }}
        onCancel={() => {
          setDeleteKeyCandidate(null);
        }}
      />
    </>
  );
}

const useStyles = makeStyles({
  actionsContainer: {
    flexGrow: 1,
    flexShrink: 0,
  },
  addKeyButton: {
    float: "right",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
    padding: tokens.spacingVerticalXXXL,
    rowGap: tokens.spacingVerticalL,
  },
  subtitle: {
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
    textAlign: "center",
  },
  title: {
    flexShrink: 0,
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
    textAlign: "center",
  },
  titleContainer: {
    alignItems: "baseline",
    display: "flex",
    gap: tokens.spacingHorizontalS,
  },
});
