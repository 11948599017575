import * as React from "react";
import { RouteProps } from "react-router-dom";
import Help from "..";

const ROUTES: RouteProps[] = [
  {
    element: <Help />,
    path: "/help",
  },
];

export default ROUTES;
