import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { ECommonCommandMessageType, ESynchronizeMessageType, ESynchronizingAction } from "@/common/constants";
import { InputWorksheetDefinition } from "@/common/types";
import { SYNCHRONIZE_API_REDUCER_PATH } from "@/ui/features/synchronize/store/synchronize/constants";
import {
  changeCurrentSynchronizingAction,
  changeNoSynchronized,
  changeTotalNoToSynchronize,
} from "@/ui/features/synchronize/store/synchronize/slice";
import { v7 as uuidv7 } from "uuid";

export function createSynchronizeWorkbookWithTemplates(
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    typeof SYNCHRONIZE_API_REDUCER_PATH
  >
) {
  return builder.query<void, SynchronizeWorkbookWithTemplatesParameters>({
    queryFn: async ({ templatesToAdd, templatesToReplace }, { dispatch }) => {
      dispatch(changeNoSynchronized(0));
      dispatch(changeTotalNoToSynchronize(templatesToAdd.length));
      dispatch(changeCurrentSynchronizingAction(ESynchronizingAction.ADD));

      for (let i = 0; i < templatesToAdd.length; i += 1) {
        dispatch(changeNoSynchronized(i + 1));

        const template = templatesToAdd[i];
        const id = uuidv7();

        await new Promise((resolve) => {
          Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, (result) => {
            const message = JSON.parse(result.message);
            if (id === message.id) {
              resolve(null);
            }
          });
          Office.context.ui.messageParent(
            JSON.stringify({ data: template, id, type: ESynchronizeMessageType.ADD_WORKSHEET })
          );
        });
      }

      dispatch(changeNoSynchronized(0));
      dispatch(changeTotalNoToSynchronize(templatesToReplace.length));
      dispatch(changeCurrentSynchronizingAction(ESynchronizingAction.REPLACE));

      for (let i = 0; i < templatesToReplace.length; i += 1) {
        dispatch(changeNoSynchronized(i + 1));

        const template = templatesToReplace[i];
        const id = uuidv7();

        await new Promise((resolve) => {
          Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, (result) => {
            const message = JSON.parse(result.message);
            if (id === message.id) {
              resolve(null);
            }
          });
          Office.context.ui.messageParent(
            JSON.stringify({ data: template, id, type: ESynchronizeMessageType.REPLACE_WORKSHEET })
          );
        });
      }

      Office.context.ui.messageParent(JSON.stringify({ type: ECommonCommandMessageType.CLOSE_DIALOG }));

      return { data: undefined };
    },
  });
}

interface SynchronizeWorkbookWithTemplatesParameters {
  templatesToAdd: InputWorksheetDefinition[];
  templatesToReplace: InputWorksheetDefinition[];
}
