import * as React from "react";
import { Button, Image, makeStyles, tokens } from "@fluentui/react-components";
import { EnvironmentManager } from "@/common/utils/environmentManager";

export interface ValidationResultStaleProps {
  onRevalidate: () => void;
}
export default function ValidationResultStale(props: ValidationResultStaleProps) {
  const { onRevalidate } = props;

  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Image className={styles.image} src={`${EnvironmentManager.getVariable("baseUrl")}/images/warning-96.png`} />
      <div className={styles.title}>{`Your validation results are outdated` /* TODO: Localization */}</div>
      <div className={styles.subtitle}>{
        `We have detected some changes in your data, please re-validate to ensure that the results are up-to-date` /* TODO: Localization */
      }</div>
      <div className={styles.revalidateButtonContainer}>
        <Button appearance="primary" onClick={onRevalidate}>
          {"Re-validate" /* TODO: Localization */}
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalM,
    height: "100%",
    justifyContent: "center",
    padding: tokens.spacingHorizontalL,
    width: "100%",
  },
  image: {
    height: "80px",
    width: "80px",
  },
  revalidateButtonContainer: {
    marginTop: tokens.spacingVerticalS,
  },
  subtitle: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
    textAlign: "center",
  },
  title: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
    textAlign: "center",
  },
});
