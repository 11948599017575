import * as React from "react";
import { ECalculationType, EDocumentSettings } from "@/common/constants";
import UnsynchronizedWorkbookError from "@/ui/features/validate/components/UnsynchronizedWorkbookError";
import ValidateWorkbook from "@/ui/features/validate/components/ValidateWorkbook/ValidateWorkbook";

export default function ValidateMain() {
  const [isWorkbookSynchronized, setIsWorkbookSynchronized] = React.useState(
    checkIsWorkbookSynchronized(Office.context.document.settings.get(EDocumentSettings.CALCULATION_TYPE))
  );

  if (!isWorkbookSynchronized) {
    return (
      <UnsynchronizedWorkbookError
        onRefresh={async () => {
          const calculationType = await new Promise((resolve) => {
            Office.context.document.settings.refreshAsync((result) => {
              resolve(result.value.get(EDocumentSettings.CALCULATION_TYPE));
            });
          });

          setIsWorkbookSynchronized(checkIsWorkbookSynchronized(calculationType));
        }}
      />
    );
  } else {
    return <ValidateWorkbook />;
  }
}

function checkIsWorkbookSynchronized(calculationType: unknown) {
  if (typeof calculationType !== "string") {
    return false;
  }
  return Object.keys(ECalculationType).includes(calculationType);
}
