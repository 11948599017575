import * as React from "react";
import { Connection } from "@/ui/features/login/types";
import { makeStyles } from "@fluentui/react-components";
import LoginForm from "@/ui/features/login/components/LoginForm";
import ManageConnections from "@/ui/features/login/components/ManageConnections";
import OTPForm from "@/ui/features/login/components/OTPForm";
import ResetPasswordForm from "@/ui/features/login/components/ResetPasswordForm";

export default function Login() {
  const styles = useStyles();

  const [isResetPasswordSuccessful, setIsResetPasswordSuccessful] = React.useState(false);
  const [currentForm, setCurrentForm] = React.useState<ObjectValues<typeof EForm>>(EForm.LOGIN);
  const [connections, setConnections] = React.useState<Connection[]>([
    {
      alias: "Numtech Production Server 1",
      url: "https://prod.ntcalcxl.com",
    },
    {
      alias: "Numtech Production Server 2",
      url: "https://prod2.ntcalcxl.com",
    },
    {
      alias: "Numtech Production Server 3",
      url: "https://prod3.ntcalcxl.com",
    },
    {
      alias: "Numtech Production Server 4",
      url: "https://prod4.ntcalcxl.com",
    },
    {
      alias: "Numtech Production Server 5",
      url: "https://prod5.ntcalcxl.com",
    },
  ]);

  return (
    <div className={styles.background}>
      {currentForm === EForm.LOGIN ? (
        <LoginForm
          connections={connections}
          isResetPasswordSuccessful={isResetPasswordSuccessful}
          onForgotPassword={() => {
            setIsResetPasswordSuccessful(false);
            setCurrentForm(EForm.OTP);
          }}
          onManageConnections={() => {
            setIsResetPasswordSuccessful(false);
            setCurrentForm(EForm.MANAGE_CONNECTIONS);
          }}
          onLoginClick={() => {
            setIsResetPasswordSuccessful(false);
          }}
        />
      ) : currentForm === EForm.OTP ? (
        <OTPForm
          onBack={() => {
            setCurrentForm(EForm.LOGIN);
          }}
          onVerifySuccess={() => {
            setCurrentForm(EForm.RESET_PASSWORD);
          }}
        />
      ) : currentForm === EForm.RESET_PASSWORD ? (
        <ResetPasswordForm
          onResetPassword={() => {
            setCurrentForm(EForm.LOGIN);
            setIsResetPasswordSuccessful(true);
          }}
        />
      ) : currentForm === EForm.MANAGE_CONNECTIONS ? (
        <ManageConnections
          connections={connections}
          onConnectionsChange={setConnections}
          onBack={() => setCurrentForm(EForm.LOGIN)}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  background: {
    alignItems: "center",
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    width: "100vw",
  },
});

const EForm = {
  LOGIN: "LOGIN",
  MANAGE_CONNECTIONS: "MANAGE_CONNECTIONS",
  OTP: "OTP",
  RESET_PASSWORD: "RESET_PASSWORD",
} as const;
