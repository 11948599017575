import { createSlice } from "@reduxjs/toolkit";

interface LoginState {}

const initialState: LoginState = {};

export const loginSlice = createSlice({
  initialState,
  name: "login",
  reducers: {},
});

export const {} = loginSlice.actions;

export default loginSlice.reducer;
