import { AddRegular, DeleteRegular } from "@fluentui/react-icons";
import {
  Button,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Switch,
  TableCellLayout,
  TableColumnDefinition,
  Text,
  createTableColumn,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { DeleteIpEntryConfirmDialog } from "@/ui/features/manage/components/DeleteIpEntryConfirmDialog";
import React from "react";

interface IpWhitelistBaseComponentEntry {
  ipAddress: string;
}

export interface IpWhitelistBaseComponentProps<T extends IpWhitelistBaseComponentEntry> {
  columns?: TableColumnDefinition<T>[];
  title: string;
  items?: T[];
  subtitle: string;
  enabled: boolean;
  onEnabledSwitch: (enabled: boolean) => void;
  onDeleteConfirm: (item: T) => void;
  onAddClick: () => void;
}

export default function IpWhitelistBaseComponent<T extends IpWhitelistBaseComponentEntry>(
  props: IpWhitelistBaseComponentProps<T>
) {
  const { columns = [], enabled, items = [], onAddClick, onDeleteConfirm, onEnabledSwitch, subtitle, title } = props;

  const styles = useStyles();

  const [itemToDelete, setItemToDelete] = React.useState<T | null>(null);

  const extendedColumns: TableColumnDefinition<T>[] = [
    ...columns,
    createTableColumn<T>({
      columnId: "ipAddress",
      compare: (a, b) => {
        return a.ipAddress.localeCompare(b.ipAddress);
      },
      renderCell: (item) => {
        return <TableCellLayout>{item.ipAddress}</TableCellLayout>;
      },
      renderHeaderCell: () => {
        return "IP Address"; // TODO: Localization
      },
    }),
    createTableColumn<T>({
      columnId: "actions",
      renderCell: (item) => {
        return (
          <Button
            aria-label="Delete"
            className={styles.deleteButton}
            icon={<DeleteRegular />}
            onClick={() => {
              setItemToDelete(item);
            }}
          />
        );
      },
      renderHeaderCell: () => {
        return "Actions"; // TODO: Localization
      },
    }),
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleAndSubtitleContainer}>
          <div className={styles.titleContainer}>
            <Text className={styles.title}>{title}</Text>
            <Switch
              label={"Enabled" /* TODO: Localization */}
              onChange={(_, { checked }) => onEnabledSwitch(checked)}
              checked={enabled}
            />
            <Button appearance="primary" icon={<AddRegular />} onClick={onAddClick}>
              {"Add" /* TODO: Localization */}
            </Button>
          </div>
          <Text className={styles.subtitle}>{subtitle}</Text>
        </div>
        <div>
          {items.length > 0 ? (
            <DataGrid
              items={items}
              columns={extendedColumns}
              sortable={enabled}
              getRowId={(item) => item.ipAddress}
              selectionAppearance={enabled ? "brand" : "none"}
              style={{ minWidth: "550px" }}
            >
              <DataGridHeader>
                <DataGridRow>
                  {({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
                </DataGridRow>
              </DataGridHeader>
              <DataGridBody<T>>
                {({ item, rowId }) => (
                  <DataGridRow<T> key={rowId}>
                    {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                  </DataGridRow>
                )}
              </DataGridBody>
            </DataGrid>
          ) : (
            <div className={styles.noConfiguredIpWhitelistEntriesContainer}>
              <Text className={styles.noConfiguredIpWhitelistEntriesTitle}>
                {"No configured IP whitelist entries" /* TODO: Localization */}
              </Text>
              <Text className={styles.noConfiguredIpWhitelistEntriesSubtitle}>
                {"Click 'Add' to start applying IP restricting policies" /* TODO: Localization */}
              </Text>
            </div>
          )}
        </div>
      </div>
      <DeleteIpEntryConfirmDialog
        open={itemToDelete !== null}
        onOk={() => {
          if (itemToDelete !== null) {
            onDeleteConfirm(itemToDelete);
          }
          setItemToDelete(null);
        }}
        onCancel={() => {
          setItemToDelete(null);
        }}
      />
    </>
  );
}

const useStyles = makeStyles({
  container: {
    backgroundColor: tokens.colorNeutralBackground1,
    margin: tokens.spacingVerticalXXXL,
    padding: tokens.spacingVerticalXXXL,
  },
  deleteButton: {
    ":not(:disabled)": {
      ":hover": {
        backgroundColor: tokens.colorStatusDangerBackground3Hover,
        color: tokens.colorNeutralForegroundOnBrand,
      },
      ":hover:active": {
        backgroundColor: tokens.colorStatusDangerBackground3Pressed,
        color: tokens.colorNeutralForegroundOnBrand,
      },
      backgroundColor: tokens.colorStatusDangerBackground3,
      color: tokens.colorNeutralForegroundOnBrand,
    },
  },
  noConfiguredIpWhitelistEntriesContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    minHeight: "200px",
    rowGap: tokens.spacingVerticalS,
  },
  noConfiguredIpWhitelistEntriesSubtitle: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
    textAlign: "center",
  },
  noConfiguredIpWhitelistEntriesTitle: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
    textAlign: "center",
  },
  subtitle: {
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
    whiteSpace: "nowrap",
  },
  title: {
    flexShrink: 0,
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
  },
  titleAndSubtitleContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingHorizontalXXS,
  },
  titleContainer: {
    alignItems: "center",
    columnGap: tokens.spacingHorizontalS,
    display: "flex",
  },
});
