import * as React from "react";
import { Button, Field, ProgressBar, makeStyles, tokens } from "@fluentui/react-components";
import CancelValidationDialog from "@/ui/features/validate/components/ValidateWorkbook/ValidationProgress/CancelValidationDialog";

export interface ValidationProgressProps {
  onCancel: () => void;
}

export default function ValidationProgress(props: ValidationProgressProps) {
  const { onCancel } = props;

  const styles = useStyles();

  const [isCancelValidationDialogVisible, setIsCancelValidationDialogVisible] = React.useState(false);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>{`Validating Your Data` /* TODO: Localization */}</div>
        <div className={styles.subtitle}>{
          `Cross-referencing your inputs and making sure all your data is valid` /* TODO: Localization */
        }</div>
        <div className={styles.progressBarContainer}>
          <Field validationMessage="Please wait a moment ..." /* TODO: Localization */ validationState="none">
            <ProgressBar />
          </Field>
        </div>
        <div className={styles.cancelButtonContainer}>
          <Button
            className={styles.cancelButton}
            onClick={() => {
              setIsCancelValidationDialogVisible(true);
            }}
          >
            {"Cancel" /* TODO: Localization */}
          </Button>
        </div>
      </div>
      {isCancelValidationDialogVisible && (
        <CancelValidationDialog
          onOk={onCancel}
          onCancel={() => {
            setIsCancelValidationDialogVisible(false);
          }}
        />
      )}
    </>
  );
}

const useStyles = makeStyles({
  cancelButton: {
    ":hover": {
      backgroundColor: tokens.colorStatusDangerBackground3Hover,
      color: tokens.colorNeutralForegroundOnBrand,
    },
    ":hover:active": {
      backgroundColor: tokens.colorStatusDangerBackground3Pressed,
      color: tokens.colorNeutralForegroundOnBrand,
    },
    backgroundColor: tokens.colorStatusDangerBackground3,
    color: tokens.colorNeutralForegroundOnBrand,
  },
  cancelButtonContainer: {
    marginTop: tokens.spacingVerticalS,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalM,
    height: "100%",
    justifyContent: "center",
    padding: tokens.spacingHorizontalL,
    width: "100%",
  },
  progressBarContainer: {
    maxWidth: "500px",
    paddingLeft: tokens.spacingHorizontalXL,
    paddingRight: tokens.spacingHorizontalXL,
    width: "100%",
  },
  subtitle: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
    textAlign: "center",
  },
  title: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
    textAlign: "center",
  },
});
