import * as React from "react";
import { Checkbox, CheckboxProps, Text, Tooltip, makeStyles } from "@fluentui/react-components";

export type HighlightErrorsCheckboxProps = CheckboxProps;

export default function HighlightErrorsCheckbox(props: HighlightErrorsCheckboxProps) {
  const { disabled } = props;

  const styles = useStyles();

  const highlightErrorsCheckbox = <Checkbox label={"Highlight errors" /* TODO: Localization */} {...props} />;

  return disabled ? (
    <Tooltip
      content={
        <Text className={styles.highlightErrorsCheckboxTooltipContentText}>{
          `Error highlighting is disabled for performance reasons when there are too many errors.` /* TODO: Localization */
        }</Text>
      }
      relationship="label"
    >
      {highlightErrorsCheckbox}
    </Tooltip>
  ) : (
    highlightErrorsCheckbox
  );
}

const useStyles = makeStyles({
  highlightErrorsCheckboxTooltipContentText: {
    maxWidth: "300px",
  },
});
