import * as React from "react";
import {
  Button,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogTitle,
  Image,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { EnvironmentManager } from "@/common/utils/environmentManager";

export interface SynchronizeConfirmDialogBodyProps {
  onOk: () => void;
  onCancel: () => void;
}

export default function SynchronizeConfirmDialogBody(props: SynchronizeConfirmDialogBodyProps) {
  const { onCancel, onOk } = props;

  const styles = useStyles();

  return (
    <DialogBody>
      <DialogTitle className={styles.dialogTitle}>
        <Image
          className={styles.dialogTitleImage}
          src={`${EnvironmentManager.getVariable("baseUrl")}/images/sync-32.png`}
        />
        <span>{"Synchronize" /* TODO: Localization */}</span>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <div>
          {
            "This synchronization process will take some time to complete, and some worksheets may be replaced, proceed?" /* TODO: Localization */
          }
        </div>
        <div className={styles.extraMessage}>
          {"* Please ensure this window stays open while synchronizing." /* TODO: Localization */}
        </div>
      </DialogContent>
      <DialogActions>
        <Button appearance="primary" onClick={onOk}>
          {"OK" /* TODO: Localization */}
        </Button>
        <Button appearance="secondary" onClick={onCancel}>
          {"Cancel" /* TODO: Localization */}
        </Button>
      </DialogActions>
    </DialogBody>
  );
}

const useStyles = makeStyles({
  dialogContent: {
    paddingBottom: tokens.spacingVerticalM,
    paddingTop: tokens.spacingVerticalM,
  },
  dialogTitle: { alignItems: "center", display: "flex", gap: "10px" },
  dialogTitleImage: { height: "24px", width: "24px" },
  extraMessage: {
    color: tokens.colorStatusDangerBackground3,
    fontWeight: "lighter",
    marginTop: tokens.spacingVerticalS,
  },
});
