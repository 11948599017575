import { CellData, InputWorksheetDefinition } from "@/common/types";
import { ECalculationType } from "@/common/constants";
import { HttpClient } from "@/common/api/httpClient";

export class CalculationHttpClient {
  public static async getInputWorksheetDefinitions(
    calculationType: ObjectValues<typeof ECalculationType>
  ): Promise<InputWorksheetDefinition[]> {
    let inputSchemaEndpoint;

    if (calculationType === ECalculationType.IRR) {
      inputSchemaEndpoint = "/api/calculation/IrrCalculations/InputSchema";
    } else if (calculationType === ECalculationType.IFRS9) {
      inputSchemaEndpoint = "/api/calculation/Ifrs9Calculations/InputSchema";
    } else if (calculationType === ECalculationType.IRRBB) {
      inputSchemaEndpoint = "/api/calculation/IrrbbCalculations/InputSchema";
    }

    if (inputSchemaEndpoint) {
      const response = await HttpClient.get(inputSchemaEndpoint);
      return response.json();
    } else {
      return [];
    }
  }

  public static async validateInputData(
    calculationType: ObjectValues<typeof ECalculationType>,
    payload: {
      data: CellData[][];
      headers: string[];
      name: string;
    }[]
  ): Promise<{
    missingInputs: string[];
    validations: {
      name: string;
      errors: {
        name: string;
        row: number;
        column: number;
        errorCode: number;
        reason: string;
      }[];
    }[];
  }> {
    let validateEndpoint;

    if (calculationType === ECalculationType.IRR) {
      validateEndpoint = "/api/calculation/IrrCalculations/Validate";
    } else if (calculationType === ECalculationType.IFRS9) {
      validateEndpoint = "/api/calculation/Ifrs9Calculations/Validate";
    } else if (calculationType === ECalculationType.IRRBB) {
      validateEndpoint = "/api/calculation/IrrbbCalculations/Validate";
    }

    if (validateEndpoint) {
      const response = await HttpClient.post(validateEndpoint, { json: payload });
      return response.json();
    } else {
      return {
        missingInputs: [],
        validations: [],
      };
    }
  }
}
