import { InputWorksheetDefinition, OutputWorksheetDefinition } from "@/common/types";
import irrbbInput from "@/commands/utils/irrbb_input.json";
import irrbbOutput from "@/commands/utils/irrbb_output.json";

export function getIRRBBInputWorksheets(): InputWorksheetDefinition[] {
  return irrbbInput as unknown as InputWorksheetDefinition[];
}

export function getIRRBBOutputWorksheets(): OutputWorksheetDefinition[] {
  return irrbbOutput as unknown as OutputWorksheetDefinition[];
}
