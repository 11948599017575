import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { CalculationHttpClient } from "@/common/api/calculationHttpClient/calculationHttpClient";
import { ECalculationType } from "@/common/constants";
import { InputWorksheetDefinition } from "@/common/types";
import { VALIDATE_API_REDUCER_PATH } from "@/ui/features/validate/store/validate/constants";

export function createGetInputWorksheetDefinitionsApi(
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    typeof VALIDATE_API_REDUCER_PATH
  >
) {
  return builder.query<InputWorksheetDefinition[], ObjectValues<typeof ECalculationType>>({
    queryFn: async (calculationType) => {
      return {
        data: await CalculationHttpClient.getInputWorksheetDefinitions(calculationType),
      };
    },
  });
}
