import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Image,
  ProgressBar,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { ECalculationStatus } from "@/ui/constants";
import { ECommonCommandMessageType } from "@/common/constants";
import { EnvironmentManager } from "@/common/utils/environmentManager";
import { useTypedSelector } from "@/ui/hooks";
import CancelCalculationConfirmDialog from "@/ui/features/calculate/components/CancelCalculationConfirmDialog";

export interface CalculatingDialogProps {
  open: boolean;
}

export default function CalculatingDialog(props: CalculatingDialogProps) {
  const styles = useStyles();

  const { open } = props;
  const [isConfirmingCancellation, setIsConfirmingCancellation] = React.useState(false);

  const calculationStatus = useTypedSelector((state) => state.calculate.calculationStatus);

  const progressBarMessage = React.useMemo(() => {
    if (calculationStatus === ECalculationStatus.NOT_STARTED) {
      return `Setting up connections ...`; // TODO: Localization
    } else if (calculationStatus === ECalculationStatus.CONNECTING) {
      return `Connecting to calculation server ...`; // TODO: Localization
    } else if (calculationStatus === ECalculationStatus.PARSING_AND_SENDING_INPUT) {
      return `Parsing and sending your data ...`; // TODO: Localization
    } else if (calculationStatus === ECalculationStatus.CALCULATING) {
      return `Calculating your data ...`; // TODO: Localization
    } else if (calculationStatus === ECalculationStatus.GENERATING_OUTPUT) {
      return `Generating output worksheets ...`; // TODO: Localization
    } else {
      return "";
    }
  }, [calculationStatus]);

  return (
    <>
      <Dialog open={open}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle className={styles.dialogTitle}>
              <Image
                className={styles.dialogTitleImage}
                src={`${EnvironmentManager.getVariable("baseUrl")}/images/calculate-32.png`}
              />
              <span>{"Calculate" /* TODO: Localization */}</span>
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <Field validationMessage={progressBarMessage} validationState="none">
                <ProgressBar />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button className={styles.cancelButton} onClick={() => setIsConfirmingCancellation(true)}>
                {"Cancel" /* TODO: Localization */}
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <CancelCalculationConfirmDialog
        open={isConfirmingCancellation}
        onOk={() => {
          Office.context.ui.messageParent(JSON.stringify({ type: ECommonCommandMessageType.CLOSE_DIALOG }));
        }}
        onCancel={() => setIsConfirmingCancellation(false)}
      />
    </>
  );
}

const useStyles = makeStyles({
  cancelButton: {
    ":hover": {
      backgroundColor: tokens.colorStatusDangerBackground3Hover,
      color: tokens.colorNeutralStrokeOnBrand2Hover,
    },
    backgroundColor: tokens.colorStatusDangerBackground3,
    color: tokens.colorNeutralStrokeOnBrand2,
  },
  dialogContent: {
    paddingBottom: tokens.spacingVerticalM,
    paddingTop: tokens.spacingVerticalM,
  },
  dialogTitle: { alignItems: "center", display: "flex", gap: "10px" },
  dialogTitleImage: { height: "24px", width: "24px" },
});
