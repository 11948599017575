import * as React from "react";
import { EnvironmentManager } from "@/common/utils/environmentManager";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import App from "./App";
import store from "./store";

(async () => {
  await Office.onReady();
  EnvironmentManager.initializeEnvironmentVariables();

  const rootElement: HTMLElement | null = document.getElementById("container");
  const root = rootElement ? createRoot(rootElement) : undefined;

  root?.render(
    <FluentProvider theme={webLightTheme}>
      <Provider store={store}>
        <App />
      </Provider>
    </FluentProvider>
  );
})();
