import * as calculateStore from "@/ui/features/calculate/store";
import * as loginStore from "@/ui/features/login/store";
import * as synchronizeStore from "@/ui/features/synchronize/store";
import * as validateStore from "@/ui/features/validate/store";
import { combineSlices, configureStore } from "@reduxjs/toolkit";

const rootReducer = combineSlices(
  ...calculateStore.slices,
  ...loginStore.slices,
  ...synchronizeStore.slices,
  ...validateStore.slices
);

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(...calculateStore.middlewares)
      .concat(...loginStore.middlewares)
      .concat(...synchronizeStore.middlewares)
      .concat(...validateStore.middlewares),
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
