import { ETableOrientation, WORKSHEET_MAXIMUM_COL_COUNT, WORKSHEET_MAXIMUM_ROW_COUNT } from "@/common/constants";

function columnToLetter(col: number) {
  let temp;
  let letter = "";

  while (col >= 0) {
    temp = col % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    col = (col - temp - 1) / 26;
  }

  return letter;
}

export function getCellAddress(
  row: number,
  col: number,
  orientation: ObjectValues<typeof ETableOrientation> = ETableOrientation.HORIZONTAL
) {
  if (orientation === ETableOrientation.HORIZONTAL) {
    if (row < 0 || row >= WORKSHEET_MAXIMUM_ROW_COUNT) {
      throw new Error(`The row value must fulfill the condition: 0 <= row < ${WORKSHEET_MAXIMUM_ROW_COUNT}.`);
    }

    if (col < 0 || col >= WORKSHEET_MAXIMUM_COL_COUNT) {
      throw new Error(`The col value must fulfill the condition: 0 <= col < ${WORKSHEET_MAXIMUM_COL_COUNT}.`);
    }

    return columnToLetter(col) + (row + 1);
  } else {
    if (col < 0 || col >= WORKSHEET_MAXIMUM_ROW_COUNT) {
      throw new Error(`The col value must fulfill the condition: 0 <= col < ${WORKSHEET_MAXIMUM_ROW_COUNT}.`);
    }

    if (row < 0 || row >= WORKSHEET_MAXIMUM_COL_COUNT) {
      throw new Error(`The row value must fulfill the condition: 0 <= row < ${WORKSHEET_MAXIMUM_COL_COUNT}.`);
    }

    return columnToLetter(row) + (col + 1);
  }
}

export function getCellRangeAddress(
  startRow: number,
  startCol: number,
  endRow: number,
  endCol: number,
  orientation: ObjectValues<typeof ETableOrientation> = ETableOrientation.HORIZONTAL
) {
  if (endRow <= startRow) {
    throw new Error("End row must be larger than start row.");
  }

  if (endCol <= startCol) {
    throw new Error("End col must be larger than start col.");
  }

  return getCellAddress(startRow, startCol, orientation) + ":" + getCellAddress(endRow - 1, endCol - 1, orientation);
}

export async function jumpToWorksheetCell(name: string, cellOrCellRangeAddress: string) {
  await Excel.run(async (context) => {
    const worksheet = context.workbook.worksheets.getItemOrNullObject(name);
    await context.sync();

    if (worksheet.isNullObject) {
      return;
    }

    worksheet.activate();
    const range = worksheet.getRange(cellOrCellRangeAddress);
    range.select();
  });
}
