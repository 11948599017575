import * as React from "react";
import {
  Caption1,
  Card,
  CardHeader,
  CardPreview,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Text,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Connection } from "@/ui/features/login/types";
import { MoreHorizontal20Regular, PlugDisconnected28Regular } from "@fluentui/react-icons";

export interface ConnectionListProps {
  connections: Connection[];
  onCardClick: (connection: Connection) => void;
  onDelete: (connection: Connection) => void;
}

export default function ConnectionList(props: ConnectionListProps) {
  const { connections, onCardClick, onDelete } = props;

  const styles = useStyles();

  return connections.map((connection) => {
    const { alias, url } = connection;

    return (
      <Card
        key={`${alias}_${url}`}
        className={styles.card}
        orientation="horizontal"
        onClick={() => onCardClick(connection)}
      >
        <CardPreview className={styles.cardPreview}>
          <PlugDisconnected28Regular />
        </CardPreview>
        <CardHeader
          header={<Text weight="semibold">{alias}</Text>}
          description={<Caption1 className={styles.caption}>{url}</Caption1>}
          action={
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <MenuButton
                  appearance="transparent"
                  icon={<MoreHorizontal20Regular />}
                  onClick={(event) => event.stopPropagation()}
                />
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem
                    className={styles.deleteMenuItem}
                    onClick={(event) => {
                      onDelete(connection);
                      event.stopPropagation();
                    }}
                  >
                    {"Delete" /* TODO: Localization */}
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          }
        />
      </Card>
    );
  });
}

const useStyles = makeStyles({
  caption: {
    color: tokens.colorNeutralForeground3,
  },
  card: {
    borderRadius: tokens.borderRadiusXLarge,
    cursor: "pointer",
    overflow: "unset",
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
    ...shorthands.borderWidth("1px"),
    ":hover": {
      backgroundColor: tokens.colorSubtleBackgroundHover,
    },
  },
  cardPreview: {
    paddingBottom: tokens.spacingHorizontalM,
    paddingLeft: tokens.spacingHorizontalM,
    paddingTop: tokens.spacingHorizontalM,
  },
  deleteMenuItem: {
    ":hover": {
      color: tokens.colorStatusDangerBackground3,
    },
    ":hover:active": {
      color: tokens.colorStatusDangerBackground3,
    },
    color: tokens.colorStatusDangerBackground3,
  },
});
