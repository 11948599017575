import { CalculationReceivingOutputState } from "@/common/api/calculationWebsocket/calculationReceivingOutput/calculationReceivingOutputState";

export class EndOfOutputState extends CalculationReceivingOutputState {
  constructor() {
    super();
  }

  public async processMessage() {
    return { isEnd: true };
  }
}
