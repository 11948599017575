import * as React from "react";
import { Dialog, DialogSurface } from "@fluentui/react-components";
import { ECalculationType, ECommonCommandMessageType, ESynchronizeMessageType } from "@/common/constants";
import { InputWorksheetDefinition } from "@/common/types";
import SynchronizeConfirmDialogBody from "@/ui/features/synchronize/components/SynchronizeConfirmDialogBody";
import SynchronizeTemplateListDialogBody from "@/ui/features/synchronize/components/SynchronizeTemplateListDialogBody";
import SynchronizingDialogBody from "@/ui/features/synchronize/components/SynchronizingDialogBody";

export default function SynchronizeConfirmDialog() {
  const [synchronizationStep, setSynchronizationStep] = React.useState<ObjectValues<typeof ESynchronizeStep>>(
    ESynchronizeStep.CHOOSING_TEMPLATE
  );
  const [templateType, setTemplateType] = React.useState<ObjectValues<typeof ECalculationType> | null>(null);

  const [templatesToAdd, setTemplatesToAdd] = React.useState<InputWorksheetDefinition[]>([]);
  const [templatesToReplace, setTemplatesToReplace] = React.useState<InputWorksheetDefinition[]>([]);

  const dialogBody = React.useMemo(() => {
    if (synchronizationStep === ESynchronizeStep.CHOOSING_TEMPLATE) {
      return (
        <SynchronizeTemplateListDialogBody
          onOk={({ templateType, templatesToAdd, templatesToReplace }) => {
            setTemplateType(templateType);
            setTemplatesToAdd(templatesToAdd);
            setTemplatesToReplace(templatesToReplace);

            setSynchronizationStep(ESynchronizeStep.CONFIRMING_SYNCHRONIZATION);
          }}
          onCancel={() => {
            Office.context.ui.messageParent(JSON.stringify({ type: ECommonCommandMessageType.CLOSE_DIALOG }));
          }}
        />
      );
    }

    if (synchronizationStep === ESynchronizeStep.CONFIRMING_SYNCHRONIZATION) {
      return (
        <SynchronizeConfirmDialogBody
          onOk={() => {
            setSynchronizationStep(ESynchronizeStep.SYNCHRONIZING);
            Office.context.ui.messageParent(
              JSON.stringify({ data: templateType, type: ESynchronizeMessageType.CONFIRM_SYNCHRONIZATION })
            );
          }}
          onCancel={() => {
            setTemplateType(null);
            setTemplatesToAdd([]);
            setTemplatesToReplace([]);

            setSynchronizationStep(ESynchronizeStep.CHOOSING_TEMPLATE);
          }}
        />
      );
    }

    if (synchronizationStep === ESynchronizeStep.SYNCHRONIZING) {
      return <SynchronizingDialogBody templatesToAdd={templatesToAdd} templatesToReplace={templatesToReplace} />;
    }
  }, [synchronizationStep, templateType, templatesToAdd, templatesToReplace]);

  return (
    <Dialog open>
      <DialogSurface>{dialogBody}</DialogSurface>
    </Dialog>
  );
}

const ESynchronizeStep = {
  CHOOSING_TEMPLATE: "CHOOSING_TEMPLATE",
  CONFIRMING_SYNCHRONIZATION: "CONFIRMING_SYNCHRONIZATION",
  SYNCHRONIZING: "SYNCHRONIZING",
} as const;
