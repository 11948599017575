import { CalculationHttpClient } from "@/common/api/calculationHttpClient/calculationHttpClient";
import { CalculationWebsocket } from "@/common/api/calculationWebsocket/calculationWebsocket";
import { ECalculateMessageType, ECalculationType } from "@/common/constants";
import { requestDialogParent } from "@/commands/utils/dialog";

export class CalculationSendingInput {
  private static START_SENDING_INPUT_BOUNDARY = "WS_INPUT";
  private static END_SENDING_INPUT_BOUNDARY = "WS_CALCULATE";

  private currentSendingFileName: string | null = null;

  constructor(private calculationWebsocket: CalculationWebsocket) {}

  public async sendInput(calculationType: ObjectValues<typeof ECalculationType>) {
    this.startSendingInput();

    const inputWorksheetDefinitions = await CalculationHttpClient.getInputWorksheetDefinitions(calculationType);

    for (const inputWorksheetDefinition of inputWorksheetDefinitions) {
      const { name } = inputWorksheetDefinition;

      this.startSendingFileInput(name);

      const worksheetData = await requestDialogParent<any[][]>({
        data: inputWorksheetDefinition,
        type: ECalculateMessageType.GET_INPUT_DATA,
      });

      for (const worksheetDataRow of worksheetData) {
        this.sendFileInputData(worksheetDataRow);
      }

      this.endSendingFileInput();
    }

    this.endSendingInput();
  }

  private startSendingInput() {
    this.calculationWebsocket.emit(CalculationSendingInput.START_SENDING_INPUT_BOUNDARY);
  }

  private endSendingInput() {
    this.calculationWebsocket.emit(CalculationSendingInput.END_SENDING_INPUT_BOUNDARY);
  }

  private startSendingFileInput(fileName: string) {
    if (this.currentSendingFileName !== null) {
      return;
    }
    this.calculationWebsocket.emit(`FILENAME:${fileName}`);
    this.currentSendingFileName = fileName;
  }

  private endSendingFileInput() {
    if (this.currentSendingFileName === null) {
      return;
    }
    this.currentSendingFileName = null;
  }

  private sendFileInputData(data: unknown[]) {
    if (this.currentSendingFileName === null) {
      return;
    }
    this.calculationWebsocket.emit(JSON.stringify(data));
  }
}
