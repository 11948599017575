import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { CalculationHttpClient } from "@/common/api/calculationHttpClient/calculationHttpClient";
import { CellData, InputWorksheetDefinition, ValidationError } from "@/common/types";
import { ECalculationType, ETableOrientation } from "@/common/constants";
import { VALIDATE_API_REDUCER_PATH } from "@/ui/features/validate/store/validate/constants";
import { getCellAddress, transposeData } from "@/commands/utils";

export function createValidateInputWorksheetsApi(
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    typeof VALIDATE_API_REDUCER_PATH
  >
) {
  return builder.query<
    ValidationError[],
    { inputWorksheetDefinitions: InputWorksheetDefinition[]; calculationType: ObjectValues<typeof ECalculationType> }
  >({
    queryFn: async ({ calculationType, inputWorksheetDefinitions }) => {
      const { validations } = await validateData(calculationType, inputWorksheetDefinitions);
      const validationErrors = validations
        .map(({ errors, name: worksheet }) => {
          const inputWorksheetDefinition = inputWorksheetDefinitions.find(
            (inputWorksheetDefinition) => inputWorksheetDefinition.name === worksheet
          );

          if (!inputWorksheetDefinition) {
            return [];
          }

          return errors.map(({ column, reason, row }) => {
            return {
              cell:
                row >= 0 && column >= 0
                  ? getCellAddress(row + 1, column, inputWorksheetDefinition.table.orientation)
                  : undefined,
              error: reason,
              worksheet,
            };
          });
        })
        .flat();
      // TODO: Remove, just to add fake delay
      await new Promise((resolve) => setTimeout(resolve, 3000));

      return {
        data: validationErrors,
      };
    },
  });
}

async function validateData(
  calculationType: ObjectValues<typeof ECalculationType>,
  inputWorksheetDefinitions: InputWorksheetDefinition[]
): Promise<{
  missingInputs: string[];
  validations: {
    name: string;
    errors: {
      name: string;
      row: number;
      column: number;
      errorCode: number;
      reason: string;
    }[];
  }[];
}> {
  const validationPayload: {
    data: CellData[][];
    headers: string[];
    name: string;
  }[] = [];

  await Excel.run(async (context) => {
    for (let i = 0; i < inputWorksheetDefinitions.length; i += 1) {
      const {
        name,
        table: { orientation = ETableOrientation.HORIZONTAL },
      } = inputWorksheetDefinitions[i];

      const worksheet = context.workbook.worksheets.getItemOrNullObject(name);
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();

      if (worksheet.isNullObject) {
        return null;
      }

      const range = worksheet.getUsedRange(true);
      range.load("values");
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();

      const values = transposeData(range.values, orientation);

      validationPayload.push({
        data: values.slice(1),
        headers: values[0],
        name,
      });
    }
  });

  return CalculationHttpClient.validateInputData(calculationType, validationPayload);
}
