import * as React from "react";
import { makeStyles, tokens } from "@fluentui/react-components";

export default function NoConnections() {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.title}>{`No saved connections` /* TODO: Localization */}</div>
      <div className={styles.subtitle}>{
        `Please add a new connection to connect to NtCalcXL servers` /* TODO: Localization */
      }</div>
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalM,
    justifyContent: "center",
    padding: tokens.spacingHorizontalL,
  },
  subtitle: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
    textAlign: "center",
  },
  title: {
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightBold,
    textAlign: "center",
  },
});
