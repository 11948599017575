import * as React from "react";
import { ValidationError } from "@/common/types";
import ValidationResultErrors from "@/ui/features/validate/components/ValidateWorkbook/ValidationResult/ValidationResultErrors/ValidationResultErrors";
import ValidationResultNoErrors from "@/ui/features/validate/components/ValidateWorkbook/ValidationResult/ValidationResultNoErrors";

export interface ValidationResultProps {
  items: ValidationError[];
}
export default function ValidationResult(props: ValidationResultProps) {
  const { items } = props;

  if (items.length === 0) {
    return <ValidationResultNoErrors />;
  } else {
    return <ValidationResultErrors items={items} />;
  }
}
