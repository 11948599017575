import * as React from "react";
import {
  AppsRegular,
  Board20Filled,
  Board20Regular,
  DataUsageRegular,
  LockMultipleRegular,
  Person20Filled,
  Person20Regular,
  SettingsCogMultipleRegular,
  bundleIcon,
} from "@fluentui/react-icons";
import {
  NavCategory,
  NavCategoryItem,
  NavDrawer,
  NavDrawerBody,
  NavItem,
  NavSubItemGroup,
} from "@fluentui/react-nav-preview";
import { Text, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { mdiIpOutline, mdiPostOutline } from "@mdi/js";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "@mdi/react";

const Person = bundleIcon(Person20Filled, Person20Regular);
const Dashboard = bundleIcon(Board20Filled, Board20Regular);

export function SideNavigation() {
  const styles = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const selectedValue = location.pathname;
  const selectedCategoryValue = location.pathname.split("/").slice(0, -1).join("/");

  const createNavItemProps = (path: string) => {
    return {
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        navigate(path);
        event.preventDefault();
      },
      value: path,
    };
  };

  return (
    <NavDrawer
      className={styles.container}
      open={true}
      type={"inline"}
      selectedValue={selectedValue}
      selectedCategoryValue={selectedCategoryValue}
    >
      <NavDrawerBody className={styles.body}>
        <NavItem icon={<Dashboard fontSize="24px" />} {...createNavItemProps("/manage")}>
          <Text>{"Dashboard" /* TODO: Localization */}</Text>
        </NavItem>
        <NavCategory value={"/manage/security"}>
          <NavCategoryItem icon={<LockMultipleRegular fontSize="20px" />} value={"/manage/security"}>
            {"Security" /* TODO: Localization */}
          </NavCategoryItem>
          <NavSubItemGroup>
            <NavItem
              icon={<Icon path={mdiIpOutline} size="20px" />}
              {...createNavItemProps("/manage/security/ip-whitelist")}
            >
              {"IP Whitelist" /* TODO: Localization */}
            </NavItem>
          </NavSubItemGroup>
        </NavCategory>
        <NavItem icon={<Person fontSize="24px" />} {...createNavItemProps("/manage/users")}>
          {"Users" /* TODO: Localization */}
        </NavItem>
        <NavItem icon={<AppsRegular fontSize="20px" />} {...createNavItemProps("/manage/apps")}>
          {"Apps" /* TODO: Localization */}
        </NavItem>
        <NavItem icon={<DataUsageRegular fontSize="20px" />} {...createNavItemProps("/manage/usage")}>
          {"Usage" /* TODO: Localization */}
        </NavItem>
        <NavItem icon={<Icon path={mdiPostOutline} size="20px" />} {...createNavItemProps("/manage/audit")}>
          {"Audit" /* TODO: Localization */}
        </NavItem>
        <NavItem icon={<SettingsCogMultipleRegular fontSize="20px" />} {...createNavItemProps("/manage/settings")}>
          {"Settings" /* TODO: Localization */}
        </NavItem>
      </NavDrawerBody>
    </NavDrawer>
  );
}

const useStyles = makeStyles({
  body: {},
  container: {
    backgroundColor: tokens.colorNeutralBackground4,
    ...shorthands.borderRight("1px", "solid", tokens.colorNeutralStencil1Alpha),
  },
});
