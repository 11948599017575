import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Input,
  Label,
} from "@fluentui/react-components";

export interface AddUserBasedIpEntryDialogProps {
  open: boolean;
  onOk: (entry: { ipAddress: string; username: string }) => void;
  onCancel: () => void;
}
export function AddUserBasedIpEntryDialog(props: AddUserBasedIpEntryDialogProps) {
  const { onCancel, onOk, open } = props;

  const [username, setUsername] = React.useState("");
  const [ipAddress, setIpAddress] = React.useState("");

  const areFieldsFilled = !!(ipAddress && username);

  return (
    <Dialog open={open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{"Add IP Entry" /* TODO: Localization */}</DialogTitle>
          <DialogContent>
            <Field label={<Label>{"Username" /* TODO: Localization */}</Label>} validationState="none" required>
              <Input value={username} onChange={(_, { value }) => setUsername(value)} />
            </Field>
            <Field label={<Label>{"IP Address" /* TODO: Localization */}</Label>} validationState="none" required>
              <Input value={ipAddress} onChange={(_, { value }) => setIpAddress(value)} />
            </Field>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!areFieldsFilled}
              appearance="primary"
              onClick={() => {
                onOk({ ipAddress, username });
              }}
            >
              {"OK" /* TODO: Localization */}
            </Button>
            <Button appearance="secondary" onClick={onCancel}>
              {"Cancel" /* TODO: Localization */}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
