import * as React from "react";
import { RouteProps } from "react-router-dom";
import FRMCopilot from "..";

const ROUTES: RouteProps[] = [
  {
    element: <FRMCopilot />,
    path: "/copilot",
  },
];

export default ROUTES;
