import * as forge from "node-forge";

export function generateKeyPair(keyLength: number) {
  return forge.pki.rsa.generateKeyPair({ bits: keyLength, workers: -1 });
}

export function getPublicKeyWithBoundary(publicKey: forge.pki.rsa.PublicKey) {
  return forge.pki.publicKeyToPem(publicKey);
}

export function getPrivateKeyWithBoundary(privateKey: forge.pki.rsa.PrivateKey) {
  return forge.pki.privateKeyToPem(privateKey);
}

export function validatePublicKeyPem(publicKeyPem: string) {
  try {
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    return !!(publicKey.n && publicKey.e);
  } catch (err) {
    return false;
  }
}

export function getPublicKeyFingerprint(publicKeyPem: string) {
  const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

  const publicKeyDer = forge.asn1.toDer(forge.pki.publicKeyToAsn1(publicKey)).getBytes();

  const sha256 = forge.md.sha256.create();
  sha256.update(publicKeyDer);

  return sha256.digest().toHex();
}

export function formatPublicKeyFingerprint(publicKeyPemHex: string) {
  const firstSixteenQuartets = publicKeyPemHex.substring(0, 16 * 2);
  return firstSixteenQuartets.match(/.{2}/g)?.join(":") ?? firstSixteenQuartets;
}
