import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { makeStyles } from "@fluentui/react-components";
import ROUTES from "./routes";

const useStyles = makeStyles({
  background: {
    height: "100vh",
    width: "100vw",
  },
});

export default function App() {
  const styles = useStyles();

  return (
    <div className={styles.background}>
      <BrowserRouter>
        <Routes>
          {ROUTES.map((route, index) => (
            <Route key={index} {...route} />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}
