import * as React from "react";
import { ECalculationType, ECommonCommandMessageType, EDocumentSettings } from "@/common/constants";
import { useSearchParams } from "react-router-dom";
import SupportedCalculationDialog from "@/ui/features/calculate/components/SupportedCalculationDialog";
import UnsupportedCalculationDialog from "@/ui/features/calculate/components/UnsupportedCalculationDialog";

export default function CalculateMain() {
  const [searchParams] = useSearchParams(window.location.href.substring(window.location.href.indexOf("?")));

  const calculationType = searchParams.get(EDocumentSettings.CALCULATION_TYPE);

  if (isValidCalculationType(calculationType)) {
    return <SupportedCalculationDialog open calculationType={calculationType} />;
  } else {
    return (
      <UnsupportedCalculationDialog
        open
        onOk={() => {
          Office.context.ui.messageParent(JSON.stringify({ type: ECommonCommandMessageType.CLOSE_DIALOG }));
        }}
      />
    );
  }
}

function isValidCalculationType(
  calculationType: string | null
): calculationType is ObjectValues<typeof ECalculationType> {
  return typeof calculationType === "string" && Object.keys(ECalculationType).includes(calculationType);
}
