import * as React from "react";
import { keyframes } from "@fluentui/merge-styles";
import { makeStyles } from "@fluentui/react-components";
import CalculateMain from "@/ui/features/calculate/components/CalculateMain";

const backgroundFadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const useStyles = makeStyles({
  background: {
    animation: `${backgroundFadeIn} 1.5s`,
    background: "linear-gradient(90deg, rgba(78,101,255,1) 0%, rgba(146,239,253,1) 100%)",
    height: "100vh",
    width: "100vw",
  },
});

export default function Calculate() {
  const styles = useStyles();

  return (
    <div className={styles.background}>
      <CalculateMain />
    </div>
  );
}
