import * as React from "react";
import { ArrowClockwiseRegular } from "@fluentui/react-icons";
import { Button, Image, makeStyles, tokens } from "@fluentui/react-components";
import { EnvironmentManager } from "@/common/utils/environmentManager";

interface ValidationCancelledProps {
  onRevalidate: () => void;
}

export default function ValidationCancelled(props: ValidationCancelledProps) {
  const { onRevalidate } = props;

  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Image className={styles.image} src={`${EnvironmentManager.getVariable("baseUrl")}/images/cancel-96.png`} />
      <div className={styles.title}>{`Validation cancelled` /* TODO: Localization */}</div>
      <div className={styles.subtitle}>{
        `You can re-validate your data or close this window.` /* TODO: Localization */
      }</div>
      <div className={styles.actionContainer}>
        <Button icon={<ArrowClockwiseRegular />} appearance="primary" onClick={onRevalidate}>
          {"Re-validate" /* TODO: Localization */}
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  actionContainer: {
    alignItems: "center",
    display: "flex",
    gap: tokens.spacingHorizontalM,
    marginTop: tokens.spacingVerticalM,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalM,
    height: "100%",
    justifyContent: "center",
    padding: tokens.spacingHorizontalM,
  },
  image: {
    height: "80px",
    width: "80px",
  },
  subtitle: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
    textAlign: "center",
  },
  title: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
    textAlign: "center",
  },
});
