import { ValidationError } from "@/common/types";
import {
  highlightCellsWithError,
  unhighlightCellsWithError,
} from "@/ui/features/validate/components/ValidateWorkbook/ValidationResult/ValidationResultErrors/ValidationResultErrors.util";
import React from "react";

export function useHighlightUnhighlightErrors(highlightErrors: boolean, validationErrors: ValidationError[]) {
  React.useEffect(() => {
    if (highlightErrors) {
      highlightCellsWithError(validationErrors);

      return () => {
        unhighlightCellsWithError(validationErrors);
      };
    }
  }, [validationErrors, highlightErrors]);
}
