import * as React from "react";
import { Button, Image, Input, Label, makeStyles, tokens } from "@fluentui/react-components";
import { EnvironmentManager } from "@/common/utils/environmentManager";
import { SendRegular } from "@fluentui/react-icons";

export default function Login() {
  const styles = useStyles();

  const bodyRef = React.useRef<HTMLDivElement>(null);

  const [inputMessage, setInputMessage] = React.useState("");
  const [messages, setMessages] = React.useState([
    {
      isUserMessage: true,
      message: "Hi FRMCopilot",
    },
    {
      isUserMessage: false,
      message: "Greetings traveller.",
    },
  ]);

  const submitMessage = () => {
    setMessages((previousMessages) => {
      return [
        ...previousMessages,
        {
          isUserMessage: true,
          message: inputMessage,
        },
      ];
    });
    setInputMessage("");
  };

  React.useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }
  }, [messages]);

  React.useEffect(() => {
    let reply: { message: string; isUserMessage: boolean };
    const lastMessageIsFromUser = messages[messages.length - 1].isUserMessage;
    if (lastMessageIsFromUser) {
      const userMessage = messages[messages.length - 1].message;

      if (userMessage.includes("spoon")) {
        reply = {
          isUserMessage: false,
          message: `Do not try and bend the spoon. That's impossible. Instead… only try to realize the truth. There is no spoon.`,
        };
      } else if (userMessage.includes("feature")) {
        reply = {
          isUserMessage: false,
          message: `Hi John Doe 😊
          
Thank you for choosing NtCalcXL. According to the latest version, we support IFRS9 and IRRBB calculations.
  
To get you started, you may follow these steps:

1. Synchronize - Choose a regulation and synchronize your Workbook with the input templates
2. Populating input data - Fill up the tabs that has a yellow highlight with the corresponding data
3. Calculate - Start calculation and wait for your results to be populated
4. Interpret output data - Check your output data by selecting the blue highlighted tabs and start creating visualizations or export the data for other purposes`,
        };
      } else {
        reply = {
          isUserMessage: false,
          message: `I am not sure if I am understanding you correctly, could you try again?`,
        };
      }

      setMessages((previousMessages) => {
        return [...previousMessages, reply];
      });
    }
  }, [messages]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.logoContainer}>
          <Image src={`${EnvironmentManager.getVariable("baseUrl")}/images/logo-80.png`} width={48} height={48} />
        </div>
        <div className={styles.companyNameAndSloganContainer}>
          <div className={styles.companyNameContainer}>
            <Label className={styles.companyNameLabel}>FRMCopilot</Label>
          </div>
        </div>
      </div>
      <div ref={bodyRef} className={styles.body}>
        {messages.map(({ isUserMessage, message }, index) => {
          if (isUserMessage) {
            return (
              <div className={styles.userSpeechBubbleContainer} key={index}>
                <div className={styles.userSpeechBubble}>{message}</div>
              </div>
            );
          } else {
            return (
              <div className={styles.gptSpeechBubbleContainer} key={index}>
                <div className={styles.gptSpeechBubble}>{message}</div>
              </div>
            );
          }
        })}
      </div>
      <div className={styles.footer}>
        <div className={styles.footerControls}>
          <Input
            style={{ flexGrow: 1 }}
            placeholder={"Type a message" /* TODO: Localization */}
            autoFocus
            value={inputMessage}
            onChange={(event) => setInputMessage(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                submitMessage();
              }
            }}
          />
          <Button icon={<SendRegular />} onClick={submitMessage} />
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  body: {
    background: tokens.colorNeutralForegroundInverted,
    marginTop: "auto",
    overflow: "auto",
    paddingLeft: tokens.spacingHorizontalXXL,
    paddingRight: tokens.spacingHorizontalXXL,
    whiteSpace: "break-spaces",
  },
  companyNameAndSloganContainer: { display: "flex", flexDirection: "column", rowGap: tokens.spacingVerticalSNudge },
  companyNameContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  companyNameLabel: {
    fontSize: tokens.fontSizeBase600,
    fontWeight: tokens.fontWeightBold,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    rowGap: tokens.spacingVerticalL,
    width: "100%",
  },
  footer: { backgroundColor: tokens.colorNeutralBackground4, width: "100%" },
  footerControls: {
    columnGap: tokens.spacingHorizontalM,
    display: "flex",
    paddingBottom: tokens.spacingVerticalM,
    paddingLeft: tokens.spacingHorizontalXXL,
    paddingRight: tokens.spacingHorizontalXXL,
    paddingTop: tokens.spacingVerticalM,
  },
  gptSpeechBubble: {
    "::after": {
      borderBottomColor: "transparent",
      borderBottomRightRadius: "40px",
      borderBottomStyle: "solid",
      borderBottomWidth: "0px",
      borderLeftColor: "transparent",
      borderLeftStyle: "solid",
      borderLeftWidth: "0px",
      borderRightColor: tokens.colorNeutralBackground4,
      borderRightStyle: "solid",
      borderRightWidth: "15px",
      borderTopColor: "transparent",
      borderTopRightRadius: "20px",
      borderTopStyle: "solid",
      borderTopWidth: "15px",
      bottom: "0px",
      content: "''",
      left: "-5px",
      position: "absolute",
    },
    backgroundColor: tokens.colorNeutralBackground4,
    borderRadius: "10px",
    color: tokens.colorNeutralStrokeAccessible,
    display: "inline-block",
    maxWidth: "calc(100% - 30px)",
    overflowWrap: "break-word",
    padding: "10px",
    position: "relative",
    textAlign: "left",
  },
  gptSpeechBubbleContainer: {
    display: "flex",
    marginBottom: tokens.spacingVerticalM,
  },
  header: {
    backgroundColor: tokens.colorNeutralBackground4,
    display: "flex",
    flexDirection: "column",
    paddingBottom: tokens.spacingVerticalM,
    paddingTop: tokens.spacingVerticalM,
    rowGap: tokens.spacingVerticalM,
  },
  logoContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  userSpeechBubble: {
    "::after": {
      borderBottomColor: "transparent",
      borderBottomLeftRadius: "40px",
      borderBottomStyle: "solid",
      borderBottomWidth: "0px",
      borderLeftColor: "#579aff",
      borderLeftStyle: "solid",
      borderLeftWidth: "15px",
      borderRightColor: "transparent",
      borderRightStyle: "solid",
      borderRightWidth: "0px",
      borderTopColor: "transparent",
      borderTopLeftRadius: "20px",
      borderTopStyle: "solid",
      borderTopWidth: "15px",
      bottom: "0px",
      content: "''",
      position: "absolute",
      right: "-5px",
    },
    backgroundColor: "#579aff",
    borderRadius: "10px",
    color: "#ffffff",
    display: "inline-block",
    maxWidth: "calc(100% - 30px)",
    overflowWrap: "break-word",
    padding: "10px",
    position: "relative",
    textAlign: "left",
  },
  userSpeechBubbleContainer: {
    display: "flex",
    justifyContent: "end",
    marginBottom: tokens.spacingVerticalM,
  },
});
