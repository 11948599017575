import { AddOrganizationBasedIpEntryDialog } from "@/ui/features/manage/components/AddOrganizationBasedIpEntryDialog";
import IpWhitelistBaseComponent from "@/ui/features/manage/components/IpWhitelistBaseComponent";
import React from "react";

interface OrganizationBasedIpWhitelistEntry {
  ipAddress: string;
}

export interface OrganizationBasedIpWhitelistProps {
  enabled: boolean;
  onEnabledSwitch: (enabled: boolean) => void;
}

export default function OrganizationBasedIpWhitelist(props: OrganizationBasedIpWhitelistProps) {
  const [isAddOrganizationBasedIpEntryDialogOpen, setIsAddOrganizationBasedIpEntryDialogOpen] = React.useState(false);
  const [items, setItems] = React.useState<OrganizationBasedIpWhitelistEntry[]>([
    {
      ipAddress: "143.255.247.46",
    },
    {
      ipAddress: "120.140.227.155",
    },
    {
      ipAddress: "52.255.203.109",
    },
    {
      ipAddress: "231.52.92.101",
    },
    {
      ipAddress: "205.62.93.241",
    },
    {
      ipAddress: "11.165.51.18 - 11.165.51.254",
    },
    {
      ipAddress: "205.49.45.104",
    },
    {
      ipAddress: "238.13.187.18 - 238.125.128.18",
    },
    {
      ipAddress: "43.44.215.41",
    },
    {
      ipAddress: "41.46.166.169",
    },
  ]);

  return (
    <>
      <IpWhitelistBaseComponent
        title={"Organization-based IP Whitelist" /* TODO: Localization */}
        subtitle={"Control IP restrictions on an organization level" /* TODO: Localization */}
        items={items}
        onDeleteConfirm={(itemToDelete) => setItems((prev) => prev.filter((item) => item !== itemToDelete))}
        onAddClick={() => setIsAddOrganizationBasedIpEntryDialogOpen(true)}
        {...props}
      />
      {isAddOrganizationBasedIpEntryDialogOpen && (
        <AddOrganizationBasedIpEntryDialog
          open={isAddOrganizationBasedIpEntryDialogOpen}
          onOk={(entry) => {
            setItems((prev) => [...prev, entry]);
            setIsAddOrganizationBasedIpEntryDialogOpen(false);
          }}
          onCancel={() => setIsAddOrganizationBasedIpEntryDialogOpen(false)}
        />
      )}
    </>
  );
}
