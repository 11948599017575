import { VALIDATE_API_REDUCER_PATH } from "@/ui/features/validate/store/validate/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createGetInputWorksheetDefinitionsApi } from "@/ui/features/validate/store/validate/apis/getInputWorksheetDefinitions";
import { createValidateInputWorksheetsApi } from "@/ui/features/validate/store/validate/apis/validateInputWorksheets";

export const validateApiSlice = createApi({
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getInputWorksheetDefinitions: createGetInputWorksheetDefinitionsApi(builder),
    validateInputWorksheets: createValidateInputWorksheetsApi(builder),
  }),
  reducerPath: VALIDATE_API_REDUCER_PATH,
});

export const { useGetInputWorksheetDefinitionsQuery, useValidateInputWorksheetsQuery } = validateApiSlice;
