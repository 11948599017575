import * as React from "react";
import { EDocumentSettings } from "@/common/constants";
import {
  useGetInputWorksheetDefinitionsQuery,
  useValidateInputWorksheetsQuery,
} from "@/ui/features/validate/store/validate";
import { useInputWorksheetsDataStaleCheck } from "@/ui/features/validate/components/ValidateWorkbook/ValidateWorkbook.hooks";
import ValidationCancelled from "@/ui/features/validate/components/ValidateWorkbook/ValidationCancelled";
import ValidationFailed from "@/ui/features/validate/components/ValidateWorkbook/ValidationFailed";
import ValidationProgress from "@/ui/features/validate/components/ValidateWorkbook/ValidationProgress/ValidationProgress";
import ValidationResult from "@/ui/features/validate/components/ValidateWorkbook/ValidationResult/ValidationResult";
import ValidationResultStale from "@/ui/features/validate/components/ValidateWorkbook/ValidationResultStale";

export default function ValidateWorkbook() {
  const [isValidationCancelled, setIsValidationCancelled] = React.useState(false);

  const calculationType = Office.context.document.settings.get(EDocumentSettings.CALCULATION_TYPE);

  const {
    data: inputWorksheetDefinitions = [],
    isFetching: isFetchingInputWorksheetDefinitions,
    isSuccess: isFetchingInputWorksheetDefinitionsSuccess,
    refetch: refetchInputWorksheetDefinitions,
  } = useGetInputWorksheetDefinitionsQuery(calculationType);

  const {
    data: validationErrors = [],
    isFetching: isValidating,
    isSuccess: isValidationSuccess,
    refetch: revalidateInputWorksheets,
  } = useValidateInputWorksheetsQuery(
    { calculationType, inputWorksheetDefinitions },
    { skip: !isFetchingInputWorksheetDefinitionsSuccess }
  );

  const [isInputWorksheetsDataStale, setIsInputWorksheetsDataStale] =
    useInputWorksheetsDataStaleCheck(inputWorksheetDefinitions);

  const setInputWorksheetsDataNotStaleAndRevalidate = () => {
    setIsInputWorksheetsDataStale(false);
    if (isFetchingInputWorksheetDefinitionsSuccess) {
      revalidateInputWorksheets();
    } else {
      refetchInputWorksheetDefinitions();
    }
  };

  if (isFetchingInputWorksheetDefinitions || isValidating) {
    return (
      <ValidationProgress
        onCancel={() => {
          setIsValidationCancelled(true);
        }}
      />
    );
  }

  if (isInputWorksheetsDataStale) {
    return <ValidationResultStale onRevalidate={setInputWorksheetsDataNotStaleAndRevalidate} />;
  }

  if (isValidationSuccess) {
    return <ValidationResult items={validationErrors} />;
  }

  if (isValidationCancelled) {
    return (
      <ValidationCancelled
        onRevalidate={() => {
          setIsValidationCancelled(false);
          setInputWorksheetsDataNotStaleAndRevalidate();
        }}
      />
    );
  }

  return <ValidationFailed onTryAgain={setInputWorksheetsDataNotStaleAndRevalidate} />;
}
