import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  makeStyles,
  tokens,
} from "@fluentui/react-components";

export interface CancelValidationDialogProps {
  onOk: () => void;
  onCancel: () => void;
}

export default function CancelValidationDialog(props: CancelValidationDialogProps) {
  const { onCancel, onOk } = props;

  const styles = useStyles();

  return (
    <Dialog open>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{"Cancel validation" /* TODO: Localization */}</DialogTitle>
          <DialogContent className={styles.dialogContent}>
            <div>{`Are you sure you want to cancel the validation process?` /* TODO: Localization */}</div>
            <div className={styles.extraMessage}>
              {
                "* Please note that the validation cannot continue from where it was cancelled." /* TODO: Localization */
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button className={styles.okButton} onClick={onOk}>
              {"OK" /* TODO: Localization */}
            </Button>
            <Button appearance="secondary" onClick={onCancel}>
              {"Cancel" /* TODO: Localization */}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

const useStyles = makeStyles({
  dialogContent: {
    paddingBottom: tokens.spacingVerticalM,
    paddingTop: tokens.spacingVerticalM,
  },
  extraMessage: {
    color: tokens.colorStatusDangerBackground3,
    fontWeight: "lighter",
    marginTop: tokens.spacingVerticalS,
  },
  okButton: {
    ":hover": {
      backgroundColor: tokens.colorStatusDangerBackground3Hover,
      color: tokens.colorNeutralForegroundOnBrand,
    },
    ":hover:active": {
      backgroundColor: tokens.colorStatusDangerBackground3Pressed,
      color: tokens.colorNeutralForegroundOnBrand,
    },
    backgroundColor: tokens.colorStatusDangerBackground3,
    color: tokens.colorNeutralForegroundOnBrand,
  },
});
