import * as React from "react";
import {
  DataGrid,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Image,
  Link,
  SearchBox,
  SortDirection,
  TableCellLayout,
  TableColumnDefinition,
  TableColumnId,
  Text,
  createTableColumn,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { EnvironmentManager } from "@/common/utils/environmentManager";
import { TOO_MANY_ERRORS_THRESHOLD } from "@/ui/features/validate/components/ValidateWorkbook/ValidationResult/ValidationResultErrors/ValidationResultErrors.constants";
import { ValidationError } from "@/common/types";
import { VirtualizerScrollView } from "@fluentui/react-components/unstable";
import { jumpToWorksheetCell } from "@/commands/utils";
import { useHighlightUnhighlightErrors } from "@/ui/features/validate/components/ValidateWorkbook/ValidationResult/ValidationResultErrors/ValidationResultErrors.hooks";
import HighlightErrorsCheckbox from "@/ui/features/validate/components/ValidateWorkbook/ValidationResult/ValidationResultErrors/HighlightErrorsCheckbox";
import NoResultsFound from "@/ui/features/validate/components/ValidateWorkbook/ValidationResult/ValidationResultErrors/NoResultsFound";
import naturalCompare from "natural-compare-lite";

export interface ValidationResultErrorsProps {
  items: ValidationError[];
}

export default function ValidationResultErrors(props: ValidationResultErrorsProps) {
  const { items } = props;

  const styles = useStyles();

  const hasTooManyErrors = items.length > TOO_MANY_ERRORS_THRESHOLD;

  const [searchInput, setSearchInput] = React.useState("");
  const [sortState, setSortState] = React.useState<{ sortColumn?: TableColumnId; sortDirection: SortDirection }>({
    sortDirection: "ascending",
  });
  const [isErrorsHighlighted, setIsErrorsHighlighted] = React.useState(hasTooManyErrors ? false : true);

  const columns: TableColumnDefinition<ValidationError>[] = React.useMemo(
    () => [
      createTableColumn<ValidationError>({
        columnId: "worksheet",
        compare: (a, b) => {
          return naturalCompare(a.worksheet, b.worksheet);
        },
        // TODO: Localization
        renderCell: (item) => {
          return <TableCellLayout truncate>{item.worksheet}</TableCellLayout>;
        },
        renderHeaderCell: () => "Worksheet", // TODO: Localization
      }),
      createTableColumn<ValidationError>({
        columnId: "cell",
        compare: (a, b) => {
          return naturalCompare(a.cell ?? "", b.cell ?? "");
        },
        // TODO: Localization
        renderCell: (item) => {
          return (
            <TableCellLayout>
              {item.cell ? (
                <Link
                  onClick={() => {
                    if (item.cell) {
                      jumpToWorksheetCell(item.worksheet, item.cell);
                    }
                  }}
                >
                  {item.cell}
                </Link>
              ) : (
                "-"
              )}
            </TableCellLayout>
          );
        },
        renderHeaderCell: () => "Cell", // TODO: Localization
      }),
      createTableColumn<ValidationError>({
        columnId: "error",
        compare: (a, b) => {
          return naturalCompare(a.error, b.error);
        },
        // TODO: Localization
        renderCell: (item) => {
          return <TableCellLayout truncate>{item.error}</TableCellLayout>;
        },
        renderHeaderCell: () => "Error", // TODO: Localization
      }),
    ],
    []
  );

  const filteredAndSortedItems = React.useMemo(() => {
    let filteredItems = items.slice(0, TOO_MANY_ERRORS_THRESHOLD);
    if (searchInput.length > 0) {
      const lowerCasedSearchInput = searchInput.toLowerCase();
      filteredItems = filteredItems.filter(({ cell, error, worksheet }) => {
        const isSearchMatchingAnyCellValue =
          worksheet.toLowerCase().includes(lowerCasedSearchInput) ||
          cell?.toLowerCase().includes(lowerCasedSearchInput) ||
          error.toLowerCase().includes(lowerCasedSearchInput);

        return isSearchMatchingAnyCellValue;
      });
    }

    const { sortColumn, sortDirection } = sortState;
    if (sortColumn) {
      const column = columns.find((column) => column.columnId === sortColumn);
      if (column) {
        filteredItems.sort(column.compare);
        if (sortDirection === "descending") {
          filteredItems.reverse();
        }
      }
    }

    return filteredItems;
  }, [columns, items, searchInput, sortState]);

  const subtitle = React.useMemo(() => {
    let noOfIssuesFound = items.length.toString();
    if (hasTooManyErrors) {
      noOfIssuesFound = `${TOO_MANY_ERRORS_THRESHOLD}+`;
    }

    return `* ${noOfIssuesFound} issue(s) found`; /* TODO: Localization */
  }, [hasTooManyErrors, items.length]);

  useHighlightUnhighlightErrors(isErrorsHighlighted, items);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Image className={styles.image} src={`${EnvironmentManager.getVariable("baseUrl")}/images/error-96.png`} />
        <Text className={styles.title}>{"Validation results" /* TODO: Localization */}</Text>
        <Text className={styles.subtitle}>{subtitle}</Text>
      </div>
      <div className={styles.actionsContainer}>
        <SearchBox
          className={styles.searchBox}
          value={searchInput}
          placeholder={"Search" /* TODO: Localization */}
          onChange={(_, data) => setSearchInput(data.value)}
        />
        <HighlightErrorsCheckbox
          checked={isErrorsHighlighted}
          disabled={hasTooManyErrors}
          onChange={(_, data) => {
            if (typeof data.checked === "boolean" && !hasTooManyErrors) {
              setIsErrorsHighlighted(data.checked);
            }
          }}
        />
      </div>

      <DataGrid
        className={styles.dataGrid}
        items={filteredAndSortedItems}
        columns={columns}
        sortable
        resizableColumns
        onSortChange={(_, sortState) => {
          setSortState(sortState);
        }}
      >
        <DataGridHeader className={styles.dataGridHeader}>
          <DataGridRow>
            {({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
          </DataGridRow>
        </DataGridHeader>
        {filteredAndSortedItems.length > 0 ? (
          <VirtualizerScrollView
            numItems={filteredAndSortedItems.length}
            itemSize={45}
            container={{ className: styles.dataGridBody }}
          >
            {(index: number) => {
              const item = filteredAndSortedItems[index];
              return (
                <DataGridRow<ValidationError> key={`${item.worksheet}_${item.cell}_${item.error}`}>
                  {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                </DataGridRow>
              );
            }}
          </VirtualizerScrollView>
        ) : (
          <NoResultsFound />
        )}
      </DataGrid>
    </div>
  );
}

const useStyles = makeStyles({
  actionsContainer: {
    alignItems: "center",
    columnGap: tokens.spacingHorizontalM,
    display: "flex",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalL,
    height: "100%",
    padding: tokens.spacingHorizontalL,
    width: "100%",
  },
  dataGrid: {
    flexGrow: 1,
    minHeight: 0,
  },
  dataGridBody: {
    height: "calc(100% - 33px)",
    overflowY: "scroll",
  },
  dataGridHeader: { width: "calc(100% - 15px)" },
  image: {
    height: "32px",
    width: "32px",
  },
  searchBox: {
    maxWidth: "250px",
    minWidth: "100px",
  },
  subtitle: {
    color: tokens.colorStatusDangerBackground3,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
    textAlign: "center",
  },
  title: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
    textAlign: "center",
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    gap: tokens.spacingHorizontalS,
  },
});
