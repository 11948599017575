import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Dropdown,
  Field,
  Input,
  Label,
  Option,
} from "@fluentui/react-components";

export interface AddRoleBasedIpEntryDialogProps {
  open: boolean;
  onOk: (entry: { ipAddress: string; role: string }) => void;
  onCancel: () => void;
}
export function AddRoleBasedIpEntryDialog(props: AddRoleBasedIpEntryDialogProps) {
  const { onCancel, onOk, open } = props;

  const [role, setRole] = React.useState("");
  const [ipAddress, setIpAddress] = React.useState("");

  const options = ["Administrator", "Power User", "General User"];

  const areFieldsFilled = !!(ipAddress && role);

  return (
    <Dialog open={open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{"Add IP Entry" /* TODO: Localization */}</DialogTitle>
          <DialogContent>
            <Field label={<Label>{"Role" /* TODO: Localization */}</Label>} validationState="none" required>
              <Dropdown
                placeholder={"Select a role" /* TODO: Localization */}
                onOptionSelect={(_, { optionValue }) => {
                  if (typeof optionValue === "string" && optionValue.length > 0) {
                    setRole(optionValue);
                  }
                }}
              >
                {options.map((option) => (
                  <Option key={option} disabled={option === role}>
                    {option}
                  </Option>
                ))}
              </Dropdown>
            </Field>
            <Field label={<Label>{"IP Address" /* TODO: Localization */}</Label>} validationState="none" required>
              <Input value={ipAddress} onChange={(_, { value }) => setIpAddress(value)} />
            </Field>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!areFieldsFilled}
              appearance="primary"
              onClick={() => {
                onOk({ ipAddress, role });
              }}
            >
              {"OK" /* TODO: Localization */}
            </Button>
            <Button appearance="secondary" onClick={onCancel}>
              {"Cancel" /* TODO: Localization */}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
