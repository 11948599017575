import * as React from "react";
import {
  Button,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogTitle,
  Field,
  Image,
  ProgressBar,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { ECommonCommandMessageType, ESynchronizingAction } from "@/common/constants";
import { EnvironmentManager } from "@/common/utils/environmentManager";
import { InputWorksheetDefinition } from "@/common/types";
import { useSynchronizeWorkbookWithTemplatesQuery } from "@/ui/features/synchronize/store/synchronize";
import { useTypedSelector } from "@/ui/hooks";
import CancelSynchronizationConfirmDialog from "@/ui/features/synchronize/components/CancelSynchronizationConfirmDialog";

export interface SynchronizingDialogBodyProps {
  templatesToAdd: InputWorksheetDefinition[];
  templatesToReplace: InputWorksheetDefinition[];
}

export default function SynchronizingDialogBody(props: SynchronizingDialogBodyProps) {
  const { templatesToAdd, templatesToReplace } = props;

  const styles = useStyles();

  const [isConfirmingCancellation, setIsConfirmingCancellation] = React.useState(false);

  useSynchronizeWorkbookWithTemplatesQuery({ templatesToAdd, templatesToReplace });
  const noSynchronized = useTypedSelector((state) => state.synchronize.noSynchronized);
  const totalNoToSynchronize = useTypedSelector((state) => state.synchronize.totalNoToSynchronize);
  const currentSynchronizingAction = useTypedSelector((state) => state.synchronize.currentSynchronizingAction);

  const progressBarMessage = React.useMemo(() => {
    if (currentSynchronizingAction === ESynchronizingAction.ADD) {
      return `Adding ${noSynchronized} of ${totalNoToSynchronize} worksheets ...`; // TODO: Localization
    } else if (currentSynchronizingAction === ESynchronizingAction.REPLACE) {
      return `Replacing ${noSynchronized} of ${totalNoToSynchronize} worksheets ...`; // TODO: Localization
    } else {
      return "";
    }
  }, [currentSynchronizingAction, noSynchronized, totalNoToSynchronize]);

  return (
    <>
      <DialogBody>
        <DialogTitle className={styles.dialogTitle}>
          <Image
            className={styles.dialogTitleImage}
            src={`${EnvironmentManager.getVariable("baseUrl")}/images/sync-32.png`}
          />
          <span>{"Synchronizing" /* TODO: Localization */}</span>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <Field validationMessage={progressBarMessage} validationState="none">
            <ProgressBar value={noSynchronized / totalNoToSynchronize} />
          </Field>
        </DialogContent>
        <DialogActions>
          <Button className={styles.cancelButton} onClick={() => setIsConfirmingCancellation(true)}>
            {"Cancel" /* TODO: Localization */}
          </Button>
        </DialogActions>
      </DialogBody>
      <CancelSynchronizationConfirmDialog
        open={isConfirmingCancellation}
        onOk={() => {
          Office.context.ui.messageParent(JSON.stringify({ type: ECommonCommandMessageType.CLOSE_DIALOG }));
        }}
        onCancel={() => setIsConfirmingCancellation(false)}
      />
    </>
  );
}

const useStyles = makeStyles({
  cancelButton: {
    ":hover": {
      backgroundColor: tokens.colorStatusDangerBackground3Hover,
      color: tokens.colorNeutralStrokeOnBrand2Hover,
    },
    backgroundColor: tokens.colorStatusDangerBackground3,
    color: tokens.colorNeutralStrokeOnBrand2,
  },
  dialogContent: {
    paddingBottom: tokens.spacingVerticalM,
    paddingTop: tokens.spacingVerticalM,
  },
  dialogTitle: { alignItems: "center", display: "flex", gap: "10px" },
  dialogTitleImage: { height: "24px", width: "24px" },
});
