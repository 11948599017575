import {
  CellData,
  CellFormat,
  InputTableDefinition,
  InputWorksheetDefinition,
  OutputTableDefinition,
  OutputWorksheetDefinition,
} from "@/common/types";
import { ETableOrientation } from "@/common/constants";
import {
  autoFitWorksheet,
  setBorders,
  setFill,
  setFont,
  setHorizontalAlignment,
  setNumberFormat,
  setVerticalAlignment,
} from "@/commands/utils/worksheets/formats";
import { getCellRangeAddress } from "@/commands/utils/worksheets/navigation";
import { transpose } from "@/common/utils/arrays";
import merge from "deepmerge";

export function transposeData<T>(
  data: T[][],
  orientation: ObjectValues<typeof ETableOrientation> = ETableOrientation.HORIZONTAL
) {
  if (orientation === ETableOrientation.HORIZONTAL) {
    return data;
  } else {
    return transpose(data);
  }
}

export async function deleteWorksheet(name: string) {
  await Excel.run(async (context) => {
    const worksheet = context.workbook.worksheets.getItemOrNullObject(name);
    worksheet.load("visibility");
    await context.sync();

    if (worksheet.isNullObject) {
      return;
    }

    if (worksheet.visibility === "VeryHidden") {
      worksheet.visibility = "Hidden";
    }
    worksheet.delete();
  });
}

export async function setWorksheetContents(
  inputOutputWorksheetDefinition: InputWorksheetDefinition | OutputWorksheetDefinition
) {
  await Excel.run(async (context) => {
    const { name, tabColor = "", table } = inputOutputWorksheetDefinition;

    // Step 1: Check worksheet existence to add or clear, and color tab
    let worksheet = context.workbook.worksheets.getItemOrNullObject(name);

    await context.sync();

    if (worksheet.isNullObject) {
      worksheet = context.workbook.worksheets.add(name);
    } else {
      worksheet.getRange().clear();
    }

    /* Step 2: Fill in header and content data and formatting
      IMPORTANT: Formatting should be done first as it sets cell data formats and Excel might unexpectedly parse the data
      Affected case 1: Strings that are meant as dates like "20190128" will be automatically parsed into number 20190128
      Affected case 2: Date strings like "2019-01-28" will be parsed into a number like 43463 which represents number of days after 1900-Jan-0; See: http://www.cpearson.com/excel/datetime.htm
    */

    const { colWidths = [], format = {}, formats = [], rowHeights = [] } = table;

    let orientation: ObjectValues<typeof ETableOrientation> = ETableOrientation.HORIZONTAL;
    let rowOffset = 0;
    let colOffset = 0;
    let data: CellData[][] = [];

    const isInputTable = isInputTableDefinition(table);
    if (isInputTable) {
      ({ data = data, orientation = orientation } = table);

      const { headers = [] } = table;
      data = [headers, ...data];
    } else {
      ({ colOffset = colOffset, data = data, rowOffset = rowOffset } = table);
    }

    setWorksheetFormat(worksheet, {
      colOffset,
      format,
      formats,
      isInputTable,
      orientation,
      rowOffset,
    });

    setWorksheetData(worksheet, data, { colOffset, orientation, rowOffset });

    worksheet.tabColor = tabColor;

    autoFitWorksheet(worksheet, { colOffset, colWidths, rowHeights, rowOffset });
  });
}

export async function getWorksheetNames() {
  const worksheetNames = await Excel.run(async (context) => {
    const worksheets = context.workbook.worksheets;
    worksheets.load("items/name");

    await context.sync();

    return worksheets.items.map((worksheet) => worksheet.name);
  });

  return worksheetNames;
}

export async function getWorksheetData(inputWorksheetDefinition: InputWorksheetDefinition): Promise<CellData[][]> {
  return Excel.run(async (context) => {
    const {
      name,
      table: { orientation = ETableOrientation.HORIZONTAL },
    } = inputWorksheetDefinition;

    const worksheet = context.workbook.worksheets.getItemOrNullObject(name);

    await context.sync();

    if (worksheet.isNullObject) {
      return [];
    }

    const range = worksheet.getUsedRange(true);
    range.load("values");

    await context.sync();

    return transposeData(range.values, orientation);
  });
}

function setWorksheetData(
  worksheet: Excel.Worksheet,
  data: CellData[][],
  options?: {
    orientation?: InputTableDefinition["orientation"];
    rowOffset?: OutputTableDefinition["rowOffset"];
    colOffset?: OutputTableDefinition["colOffset"];
  }
) {
  if (data.length === 0 || data[0].length === 0) {
    return;
  }

  const { colOffset = 0, orientation = ETableOrientation.HORIZONTAL, rowOffset = 0 } = options ?? {};

  const startRow = rowOffset;
  const startCol = colOffset;
  const endRow = rowOffset + Math.max(data.length, 0);
  const endCol = colOffset + Math.max(data[0].length ?? 0, 0);

  const cellRangeAddress = getCellRangeAddress(startRow, startCol, endRow, endCol, orientation);
  const range = worksheet.getRange(cellRangeAddress);
  range.values = transposeData(data, orientation);
}

function setWorksheetFormat(
  worksheet: Excel.Worksheet,
  options: {
    format: NonNullable<(InputTableDefinition | OutputTableDefinition)["format"]>;
    formats: NonNullable<(InputTableDefinition | OutputTableDefinition)["formats"]>;
    orientation: NonNullable<InputTableDefinition["orientation"]>;
    rowOffset: NonNullable<OutputTableDefinition["rowOffset"]>;
    colOffset: NonNullable<OutputTableDefinition["colOffset"]>;
    isInputTable: boolean;
  }
) {
  const { colOffset, format, formats, isInputTable, orientation, rowOffset } = options;

  for (const { endCol, endRow, formats: formatNames, startCol, startRow } of formats) {
    const cellRangeAddress = getCellRangeAddress(
      rowOffset + startRow,
      colOffset + startCol,
      rowOffset + endRow,
      colOffset + endCol,
      orientation
    );
    const range = worksheet.getRange(cellRangeAddress);

    const rangeCellFormat = merge.all(
      formatNames.map((formatName) => format[formatName]).filter((cellFormat) => !!cellFormat)
    ) as CellFormat;

    if (rangeCellFormat) {
      setRangeFormat(range, rangeCellFormat, isInputTable);
    }
  }
}

function setRangeFormat(range: Excel.Range, cellFormat: CellFormat, isInputTable: boolean) {
  const {
    border = null,
    fill = null,
    font = null,
    horizontalAlignment = null,
    merge = false,
    verticalAlignment = null,
    wrapText = false,
  } = cellFormat;

  setHorizontalAlignment(range, horizontalAlignment);

  setVerticalAlignment(range, verticalAlignment);

  range.format.wrapText = wrapText;

  if (merge) {
    range.merge();
  }

  setNumberFormat(range, cellFormat, isInputTable);

  setFill(range, fill);

  setFont(range, font);

  setBorders(range, border);
}

function isInputTableDefinition(
  tableDefinition: InputTableDefinition | OutputTableDefinition
): tableDefinition is InputTableDefinition {
  return "headers" in tableDefinition;
}
