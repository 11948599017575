import * as React from "react";
import {
  Button,
  Divider,
  Dropdown,
  Field,
  Image,
  Input,
  Label,
  Link,
  MessageBar,
  MessageBarBody,
  MessageBarIntent,
  Option,
  Spinner,
  makeStyles,
  mergeClasses,
  tokens,
} from "@fluentui/react-components";
import { Connection } from "@/ui/features/login/types";
import { EnvironmentManager } from "@/common/utils/environmentManager";
import { PasswordRegular, PersonRegular } from "@fluentui/react-icons";
import { useLazyLoginQuery } from "@/ui/features/login/store/login/api";

export interface LoginFormProps {
  connections: Connection[];
  isResetPasswordSuccessful: boolean;
  onForgotPassword: () => void;
  onManageConnections: () => void;
  onLoginClick: () => void;
}
export default function LoginForm(props: LoginFormProps) {
  const { connections, isResetPasswordSuccessful, onForgotPassword, onLoginClick, onManageConnections } = props;

  const styles = useStyles();

  const [login, loginResult] = useLazyLoginQuery();

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [connection, setConnection] = React.useState("");

  const isLoggingIn = loginResult.isFetching;
  const areFieldsFilled = username && password && connection;

  const loginOnClick = () => {
    login({ connection, password, username });
    onLoginClick();
  };

  const formFieldsOnKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      loginOnClick();
    }
  };

  const messageBar = React.useMemo(() => {
    let intent: MessageBarIntent = "info";
    let message = "";
    if (loginResult.isError) {
      intent = "error";
      message = "The username or password you entered is incorrect."; /* TODO: Localization */
    } else if (isResetPasswordSuccessful) {
      intent = "success";
      message = "Password reset successfully! Log in to continue."; /* TODO: Localization */
    }

    if (message) {
      return (
        <MessageBar intent={intent}>
          <MessageBarBody>{message}</MessageBarBody>
        </MessageBar>
      );
    }
  }, [isResetPasswordSuccessful, loginResult.isError]);

  return (
    <div className={styles.loginFormBackground}>
      <div className={styles.logoContainer}>
        <Image src={`${EnvironmentManager.getVariable("baseUrl")}/images/logo-80.png`} width={48} height={48} />
      </div>
      <div className={styles.companyNameAndSloganContainer}>
        <div className={styles.companyNameContainer}>
          <Label className={styles.companyNameLabel}>Numerical Technologies</Label>
        </div>
        <div className={styles.sloganContainer}>
          <Label className={styles.sloganLabel}>
            {"Your trusted partner in risk management" /* TODO: Localization */}
          </Label>
        </div>
      </div>
      {messageBar && <div>{messageBar}</div>}
      <div>
        <Field
          label={
            <Label className={styles.usernameFieldLabel} required>
              {"Username" /* TODO: Localization */}
            </Label>
          }
        >
          <Input
            contentBefore={<PersonRegular className={styles.usernameFieldIcon} />}
            autoFocus
            placeholder={"Enter username" /* TODO: Localization */}
            onChange={(event) => setUsername(event.target.value)}
            onKeyDown={formFieldsOnKeyDown}
          />
        </Field>
        <Field
          label={
            <Label className={styles.passwordFieldLabel} required>
              {"Password" /* TODO: Localization */}
            </Label>
          }
        >
          <Input
            type="password"
            contentBefore={<PasswordRegular className={styles.passwordFieldIcon} />}
            contentAfter={
              <Button
                className={styles.forgotPasswordButton}
                appearance="transparent"
                disabled={!username || isLoggingIn}
                onClick={onForgotPassword}
              >
                {"Forgot" /* TODO: Localization */}
              </Button>
            }
            placeholder={"Enter password" /* TODO: Localization */}
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={formFieldsOnKeyDown}
          />
        </Field>
        <Field
          label={
            <>
              <Label className={styles.connectionFieldLabel} required>
                {"Connection" /* TODO: Localization */}
              </Label>
              <Button
                className={styles.manageConnectionsButton}
                disabled={isLoggingIn}
                style={{ float: "right" }}
                appearance="transparent"
                onClick={onManageConnections}
              >
                {"> Manage connections" /* TODO: Localization */}
              </Button>
            </>
          }
        >
          <Dropdown
            placeholder={"Select a connection" /* TODO: Localization */}
            disabled={connections.length === 0}
            value={connection}
            onOptionSelect={(_, data) => setConnection(data.optionValue ?? "")}
            onKeyDown={formFieldsOnKeyDown}
          >
            {connections.map((connection) => (
              <Option key={connection.alias}>{connection.alias}</Option>
            ))}
          </Dropdown>
        </Field>
      </div>
      <div>
        <Button
          className={mergeClasses(styles.loginButton, loginResult.isFetching && styles.loginButtonLoading)}
          appearance="primary"
          icon={loginResult.isFetching ? <Spinner size="tiny" /> : <></>}
          disabled={!areFieldsFilled}
          onClick={loginOnClick}
        >
          {"Log In" /* TODO: Localization */}
        </Button>
      </div>
      <Divider />
      <div className={styles.legalContainer}>
        <div className={styles.termsAndConditionsAndPrivacyPolicyContainer}>
          <Link
            className={styles.termsAndConditionsLink}
            onClick={() => {
              window.open("https://www.numtech.com/legal");
            }}
          >
            {"Terms & Conditions" /* TODO: Localization */}
          </Link>
          <Divider className={styles.termsAndConditionsAndPrivacyPolicyDivider} vertical />
          <Link
            className={styles.privacyPolicyLink}
            onClick={() => {
              window.open("https://www.numtech.com/privacy-policy");
            }}
          >
            {"Privacy Policy" /* TODO: Localization */}
          </Link>
        </div>
        <div className={styles.copyrightLabel}>
          {"© 2024 Numerical Technologies Pte Ltd. All rights reserved." /* TODO: Localization */}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  companyNameAndSloganContainer: { display: "flex", flexDirection: "column", rowGap: tokens.spacingVerticalSNudge },
  companyNameContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  companyNameLabel: {
    fontSize: tokens.fontSizeBase600,
    fontWeight: tokens.fontWeightBold,
  },
  connectionFieldLabel: {
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    textTransform: "uppercase",
  },
  copyrightLabel: {
    display: "flex",
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightRegular,
    justifyContent: "center",
    letterSpacing: "0.75px",
    textTransform: "uppercase",
  },
  forgotPasswordButton: {
    ":not(:disabled)": {
      color: tokens.colorBrandStroke1,
    },
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    minWidth: "unset",
    padding: "unset",
    textTransform: "uppercase",
  },
  legalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    rowGap: tokens.spacingVerticalSNudge,
  },
  loginButton: {
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    textTransform: "uppercase",
    width: "100%",
  },
  loginButtonLoading: {
    ":hover": {
      backgroundColor: tokens.colorBrandBackground,
      cursor: "not-allowed",
    },
    opacity: 0.7,
  },
  loginFormBackground: {
    background: tokens.colorNeutralForegroundInverted,
    borderRadius: tokens.borderRadiusXLarge,
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
    display: "flex",
    flexDirection: "column",
    padding: tokens.spacingVerticalXXXL,
    rowGap: tokens.spacingVerticalL,
    width: "500px",
  },
  logoContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  manageConnectionsButton: {
    ":not(:disabled)": {
      color: tokens.colorBrandStroke1,
    },
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    minWidth: "unset",
    padding: "unset",
    textTransform: "uppercase",
  },
  passwordFieldIcon: { paddingRight: tokens.spacingHorizontalSNudge },
  passwordFieldLabel: {
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    textTransform: "uppercase",
  },
  privacyPolicyLink: {
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    textTransform: "uppercase",
  },
  sloganContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  sloganLabel: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
  },
  termsAndConditionsAndPrivacyPolicyContainer: { display: "flex", justifyContent: "center" },
  termsAndConditionsAndPrivacyPolicyDivider: {
    flexGrow: 0,
    marginLeft: tokens.spacingHorizontalM,
    marginRight: tokens.spacingHorizontalM,
  },
  termsAndConditionsLink: {
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    textTransform: "uppercase",
  },
  usernameFieldIcon: { paddingRight: tokens.spacingHorizontalSNudge },
  usernameFieldLabel: {
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    textTransform: "uppercase",
  },
});
