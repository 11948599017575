import { makeStyles, mergeClasses, shorthands, tokens } from "@fluentui/react-components";
import React from "react";

export interface DarkThemeTileProps {
  selected: boolean;
  onSelect: () => void;
}

export default function DarkThemeTile(props: DarkThemeTileProps) {
  const { onSelect, selected } = props;

  const styles = useStyles();

  return (
    <div className={mergeClasses(styles.outerContainer, selected && styles.outerContainerSelected)} onClick={onSelect}>
      <div>
        <div className={styles.sections}>
          <div className={styles.header}>
            <div className={styles.headerButton}></div>
          </div>
          <div className={styles.sideNavigation}></div>
          <div className={styles.mainContainer}>
            <div className={mergeClasses(styles.text, styles.longText)}></div>
            <div className={mergeClasses(styles.text, styles.normalText)}></div>
            <div className={mergeClasses(styles.text, styles.shortText)}></div>
            <div className={mergeClasses(styles.text, styles.longText)}></div>
            <div className={styles.button}></div>
          </div>
        </div>
        <div className={styles.themeName}>{"Dark" /* TODO: Localization */}</div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  button: {
    backgroundColor: "rgb(0, 129, 227)",
    height: "8px",
    width: "30px",
  },
  header: {
    background: "rgb(32, 31, 30)",
    boxShadow: "rgba(255, 255, 255, 0.95) 0px 1px 0px",
    height: "10px",
    width: "92px",
  },
  headerButton: {
    backgroundColor: "rgb(152, 198, 255)",
    height: "8px",
    width: "8px",
  },
  longText: {
    width: "50px",
  },
  mainContainer: {
    backgroundColor: "rgb(32, 31, 30)",
    display: "inline-block",
    height: "86px",
    width: "60px",
  },
  normalText: {
    width: "40px",
  },
  outerContainer: {
    display: "inline-block",
    margin: tokens.spacingHorizontalM,
    padding: tokens.spacingHorizontalM,
    ...shorthands.borderWidth("2px"),
    ...shorthands.borderColor("rgba(0, 0, 0, 0)"),
    ...shorthands.borderStyle("solid"),
    cursor: "pointer",
  },
  outerContainerSelected: {
    ...shorthands.borderWidth("2px"),
    ...shorthands.borderColor("rgb(184, 216, 255)"),
    ...shorthands.borderStyle("solid"),
  },
  sections: {
    backgroundColor: "rgb(32, 31, 30)",
    height: "102px",
    ...shorthands.borderWidth("1px"),
    ...shorthands.borderColor(tokens.colorNeutralForegroundOnBrand),
    ...shorthands.borderStyle("solid"),
  },
  shortText: {
    width: "20px",
  },
  sideNavigation: {
    backgroundColor: "rgb(59, 58, 57)",
    display: "inline-block",
    height: "90px",
    width: "20px",
  },
  text: {
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    height: "5px",
    ...shorthands.margin("5px", "2px"),
  },
  themeName: {
    textAlign: "center",
  },
});
