import { RouteProps } from "react-router-dom";
import CALCULATE_ROUTES from "@/ui/features/calculate/routes";
import COPILOT_ROUTES from "@/ui/features/copilot/routes";
import HELP_ROUTES from "@/ui/features/help/routes";
import LOGIN_ROUTES from "@/ui/features/login/routes";
import MANAGE_ROUTES from "@/ui/features/manage/routes";
import SYNCHRONIZE_ROUTES from "@/ui/features/synchronize/routes";
import VALIDATE_ROUTES from "@/ui/features/validate/routes";

const ROUTES: RouteProps[] = [
  ...CALCULATE_ROUTES,
  ...HELP_ROUTES,
  ...LOGIN_ROUTES,
  ...COPILOT_ROUTES,
  ...MANAGE_ROUTES,
  ...SYNCHRONIZE_ROUTES,
  ...VALIDATE_ROUTES,
];

export default ROUTES;
