import { SideNavigation } from "@/ui/features/manage/components/SideNavigation";
import { makeStyles, tokens } from "@fluentui/react-components";
import React from "react";
import TopNavigation from "@/ui/features/manage/components/TopNavigation";

export interface PageViewProps {
  children: React.ReactNode;
}

export default function PageView(props: PageViewProps) {
  const { children } = props;

  const styles = useStyles();

  return (
    <div className={styles.container}>
      <TopNavigation />
      <div className={styles.body}>
        <SideNavigation />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexGrow: 1,
    minHeight: "0px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  content: { backgroundColor: tokens.colorNeutralBackground2, flexGrow: 1, overflow: "auto" },
  header: {},
  sideNavigation: {},
});
