import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Image,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { ECalculationType } from "@/common/constants";
import { EnvironmentManager } from "@/common/utils/environmentManager";

export interface CalculateConfirmDialogProps {
  calculationType: ObjectValues<typeof ECalculationType>;
  open: boolean;
  onOk: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function CalculateConfirmDialog(props: CalculateConfirmDialogProps) {
  const { calculationType, onCancel, onOk, open } = props;

  const styles = useStyles();

  return (
    <Dialog open={open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle className={styles.dialogTitle}>
            <Image
              className={styles.dialogTitleImage}
              src={`${EnvironmentManager.getVariable("baseUrl")}/images/calculate-32.png`}
            />
            <span>{"Calculate" /* TODO: Localization */}</span>
          </DialogTitle>
          <DialogContent className={styles.dialogContent}>
            <div>{
              `You are going to perform a calculation for ${calculationType}, proceed?` /* TODO: Localization */
            }</div>
            <div className={styles.extraMessage}>
              {"* Please ensure this window stays open while calculating." /* TODO: Localization */}
            </div>
          </DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={onOk}>
              {"OK" /* TODO: Localization */}
            </Button>
            <Button appearance="secondary" onClick={onCancel}>
              {"Cancel" /* TODO: Localization */}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

const useStyles = makeStyles({
  dialogContent: {
    paddingBottom: tokens.spacingVerticalM,
    paddingTop: tokens.spacingVerticalM,
  },
  dialogTitle: { alignItems: "center", display: "flex", gap: "10px" },
  dialogTitleImage: { height: "24px", width: "24px" },
  extraMessage: {
    color: tokens.colorStatusDangerBackground3,
    fontWeight: "lighter",
    marginTop: tokens.spacingVerticalS,
  },
});
