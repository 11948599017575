import DarkThemeTile from "@/ui/features/manage/components/DarkThemeTile";
import LightThemeTile from "@/ui/features/manage/components/LightThemeTile";
import PageView from "@/ui/features/manage";
import React from "react";

export default function Themes() {
  const [isLightTheme, setIsLightTheme] = React.useState(true);
  const [isDarkTheme, setIsDarkTheme] = React.useState(false);
  return (
    <PageView>
      <div>
        <LightThemeTile
          selected={isLightTheme}
          onSelect={() => {
            setIsLightTheme(true);
            setIsDarkTheme(false);
          }}
        />
        <DarkThemeTile
          selected={isDarkTheme}
          onSelect={() => {
            setIsDarkTheme(true);
            setIsLightTheme(false);
          }}
        />
      </div>
    </PageView>
  );
}
