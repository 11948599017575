import {
  ERROR_COLOR_HEX,
  ERROR_HIGHLIGHT_PROCESSING_INCREMENT,
} from "@/ui/features/validate/components/ValidateWorkbook/ValidationResult/ValidationResultErrors/ValidationResultErrors.constants";
import { ValidationError } from "@/common/types";

export async function highlightCellsWithError(items: ValidationError[]) {
  return Excel.run(async (context) => {
    const worksheetsToHighlight = parseValidationErrors(items);

    for (const { cells, worksheet: worksheetName } of worksheetsToHighlight) {
      const worksheet = context.workbook.worksheets.getItemOrNullObject(worksheetName);
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();

      if (!worksheet.isNullObject) {
        for (let i = 0; i < cells.length; i += ERROR_HIGHLIGHT_PROCESSING_INCREMENT) {
          const ranges = worksheet.getRanges(
            cells.slice(i, Math.min(cells.length, i + ERROR_HIGHLIGHT_PROCESSING_INCREMENT)).join(",")
          );
          ranges.format.fill.color = ERROR_COLOR_HEX;
        }
      }
    }
  });
}

export async function unhighlightCellsWithError(items: ValidationError[]) {
  return Excel.run(async (context) => {
    const worksheetsToUnhighlight = parseValidationErrors(items);

    for (const { cells, worksheet: worksheetName } of worksheetsToUnhighlight) {
      const worksheet = context.workbook.worksheets.getItemOrNullObject(worksheetName);
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();

      if (!worksheet.isNullObject) {
        for (let i = 0; i < cells.length; i += ERROR_HIGHLIGHT_PROCESSING_INCREMENT) {
          const ranges = worksheet.getRanges(
            cells.slice(i, Math.min(cells.length, i + ERROR_HIGHLIGHT_PROCESSING_INCREMENT)).join(",")
          );
          ranges.format.fill.clear();
        }
      }
    }
  });
}

function parseValidationErrors(items: ValidationError[]) {
  const highlightedWorksheetsMap = items.reduce((accum, { cell, worksheet }) => {
    if (cell) {
      if (accum[worksheet]) {
        accum[worksheet].push(cell);
      } else {
        accum[worksheet] = [cell];
      }
    }

    return accum;
  }, {} as Record<string, string[]>);

  return Object.keys(highlightedWorksheetsMap).map((key) => {
    return {
      cells: highlightedWorksheetsMap[key],
      worksheet: key,
    };
  });
}
