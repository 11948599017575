import { EnvironmentManager } from "@/common/utils/environmentManager";
import ky from "ky";

export class HttpClient {
  private static createUrl(url: string) {
    return new URL(url, EnvironmentManager.getVariable("backendBaseUrl")).href;
  }
  public static post(url: string, options?: Parameters<typeof ky.post>[1]) {
    return ky.post(HttpClient.createUrl(url), options);
  }
  public static get(url: string, options?: Parameters<typeof ky.get>[1]) {
    return ky.get(HttpClient.createUrl(url), options);
  }
  public static patch(url: string, options?: Parameters<typeof ky.patch>[1]) {
    return ky.patch(HttpClient.createUrl(url), options);
  }
  public static put(url: string, options?: Parameters<typeof ky.put>[1]) {
    return ky.put(HttpClient.createUrl(url), options);
  }
  public static delete(url: string, options?: Parameters<typeof ky.delete>[1]) {
    return ky.delete(HttpClient.createUrl(url), options);
  }
  public static head(url: string, options?: Parameters<typeof ky.head>[1]) {
    return ky.head(HttpClient.createUrl(url), options);
  }
}
