import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { CALCULATE_API_REDUCER_PATH } from "@/ui/features/calculate/store/calculate/constants";
import { CalculationReceivingLogs } from "@/common/api/calculationWebsocket/calculationReceivingLogs";
import { CalculationReceivingOutput } from "@/common/api/calculationWebsocket/calculationReceivingOutput/calculationReceivingOutput";
import { CalculationSendingInput } from "@/common/api/calculationWebsocket/calculationSendingInput";
import { CalculationWebsocket } from "@/common/api/calculationWebsocket/calculationWebsocket";
import { ECalculationStatus } from "@/ui/constants";
import { ECalculationType, ECommonCommandMessageType } from "@/common/constants";
import { changeCalculationStatus } from "@/ui/features/calculate/store/calculate/slice";

export function createCalculateApi(
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    typeof CALCULATE_API_REDUCER_PATH
  >
) {
  return builder.query<void, ObjectValues<typeof ECalculationType>>({
    queryFn: async (calculationType, { dispatch }) => {
      const calculationWebsocket = new CalculationWebsocket(calculationType);

      try {
        dispatch(changeCalculationStatus(ECalculationStatus.CONNECTING));

        await waitForWebsocketConnection(calculationWebsocket);

        dispatch(changeCalculationStatus(ECalculationStatus.PARSING_AND_SENDING_INPUT));

        const calculationSendingInput = new CalculationSendingInput(calculationWebsocket);
        await calculationSendingInput.sendInput(calculationType);

        dispatch(changeCalculationStatus(ECalculationStatus.CALCULATING));

        const calculationReceivingLogs = new CalculationReceivingLogs(calculationWebsocket);
        await calculationReceivingLogs.receiveLogs();

        dispatch(changeCalculationStatus(ECalculationStatus.GENERATING_OUTPUT));

        const calculationReceivingOutput = new CalculationReceivingOutput(calculationType, calculationWebsocket);
        await calculationReceivingOutput.receiveAndPopulateOutput();

        Office.context.ui.messageParent(JSON.stringify({ type: ECommonCommandMessageType.CLOSE_DIALOG }));
        return { data: undefined };
      } catch (err) {
        // TODO: Implement better errors
        return {
          error: {
            error: "",
            status: "CUSTOM_ERROR",
          },
        };
      } finally {
        calculationWebsocket.close();
      }
    },
  });
}

async function waitForWebsocketConnection(calculationWebsocket: CalculationWebsocket) {
  return Promise.race([timeoutAfterTenSeconds(), checkWebsocketConnectionPeriodically(calculationWebsocket)]);
}

async function timeoutAfterTenSeconds() {
  const tenSecondsMs = 10 * 1000;

  await new Promise((resolve) => setTimeout(resolve, tenSecondsMs));

  throw new Error("Connection timed out after waiting for 10 seconds.");
}

async function checkWebsocketConnectionPeriodically(calculationWebsocket: CalculationWebsocket) {
  const threeHundredMs = 300;

  while (!calculationWebsocket.isConnected()) {
    await new Promise((resolve) => setTimeout(resolve, threeHundredMs));
  }
}
