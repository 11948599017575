import { ECalculationStatus } from "@/ui/constants";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface CalculateState {
  calculationStatus: ObjectValues<typeof ECalculationStatus>;
}

const initialState: CalculateState = {
  calculationStatus: ECalculationStatus.NOT_STARTED,
};

export const calculateSlice = createSlice({
  initialState,
  name: "calculate",
  reducers: {
    changeCalculationStatus: (state, action: PayloadAction<ObjectValues<typeof ECalculationStatus>>) => {
      state.calculationStatus = action.payload;
    },
  },
});

export const { changeCalculationStatus } = calculateSlice.actions;
