import { CALCULATE_API_REDUCER_PATH } from "@/ui/features/calculate/store/calculate/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createCalculateApi } from "@/ui/features/calculate/store/calculate/apis/calculate";

export const calculateApiSlice = createApi({
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    calculate: createCalculateApi(builder),
  }),
  reducerPath: CALCULATE_API_REDUCER_PATH,
});

export const { useLazyCalculateQuery } = calculateApiSlice;
