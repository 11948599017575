import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Image,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { EnvironmentManager } from "@/common/utils/environmentManager";

export interface CalculationFailedDialogProps {
  open: boolean;
  onRetry: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function CalculationFailedDialog(props: CalculationFailedDialogProps) {
  const styles = useStyles();

  const { onCancel, onRetry, open } = props;

  return (
    <Dialog open={open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle className={styles.dialogTitle}>
            <Image
              className={styles.dialogTitleImage}
              src={`${EnvironmentManager.getVariable("baseUrl")}/images/error-96.png`}
            />
            <span>{"Calculation failed" /* TODO: Localization */}</span>
          </DialogTitle>
          <DialogContent className={styles.dialogContent}>
            <div>
              {
                "Something went wrong when trying to calculate, please ensure you have a stable internet connection. Retry?" /* TODO: Localization */
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={onRetry}>
              {"Retry" /* TODO: Localization */}
            </Button>
            <Button appearance="secondary" onClick={onCancel}>
              {"Cancel" /* TODO: Localization */}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

const useStyles = makeStyles({
  dialogContent: {
    paddingBottom: tokens.spacingVerticalM,
    paddingTop: tokens.spacingVerticalM,
  },
  dialogTitle: { alignItems: "center", display: "flex", gap: "10px" },
  dialogTitleImage: { height: "24px", width: "24px" },
});
