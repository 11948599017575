import { CalculationReceivingOutputState } from "@/common/api/calculationWebsocket/calculationReceivingOutput/calculationReceivingOutputState";
import { CalculationWebsocket } from "@/common/api/calculationWebsocket/calculationWebsocket";
import { ECalculateMessageType, ECalculationType } from "@/common/constants";
import { ReceivingDataState } from "@/common/api/calculationWebsocket/calculationReceivingOutput/receivingDataState";
import { getIRRBBOutputWorksheets } from "@/commands/utils/irrbb";
import { requestDialogParent } from "@/commands/utils/dialog";

export class CalculationReceivingOutput {
  private state: CalculationReceivingOutputState;

  constructor(
    private calculationType: ObjectValues<typeof ECalculationType>,
    public calculationWebsocket: CalculationWebsocket
  ) {
    this.state = new ReceivingDataState(this);
  }

  public async receiveAndPopulateOutput() {
    let isEnd = false;

    while (!isEnd) {
      const response = await this.receiveOutputData();

      const { data } = response;
      ({ isEnd } = response);

      if (data) {
        await requestDialogParent({ data, type: ECalculateMessageType.POPULATE_OUTPUT_DATA });
      }
    }

    // TODO: Remove after demo
    if (this.calculationType === ECalculationType.IRRBB) {
      const outputWorksheetDefinitions = getIRRBBOutputWorksheets();
      for (const outputWorksheetDefinition of outputWorksheetDefinitions) {
        await requestDialogParent({
          data: outputWorksheetDefinition,
          type: ECalculateMessageType.POPULATE_OUTPUT_DATA,
        });
      }
    }
  }

  public changeState(state: CalculationReceivingOutputState) {
    this.state = state;
  }

  private async receiveOutputData() {
    return Promise.race([this.listenToConnectionLoss(), this.listenToIncomingMessage()]);
  }

  private async listenToConnectionLoss(): Promise<never> {
    const threeHundredMs = 300;

    while (this.calculationWebsocket.isConnected()) {
      await new Promise((resolve) => setTimeout(resolve, threeHundredMs));
    }

    throw new Error("Lost connection while receiving logs.");
  }

  private async listenToIncomingMessage(): Promise<
    Awaited<ReturnType<CalculationReceivingOutputState["processMessage"]>>
  > {
    return new Promise((resolve, reject) => {
      const messageHandler = async (messageEvent: MessageEvent<any>) => {
        const { data } = messageEvent;

        if (typeof data !== "string") {
          reject(new Error("Invalid data received, expected string."));
        }

        try {
          const response = await this.state.processMessage(data);

          const { data: outputFileData, isEnd } = response;
          if (outputFileData || isEnd) {
            resolve({
              data: outputFileData,
              isEnd,
            });
          }
        } catch (err) {
          reject(err);
        }

        this.calculationWebsocket.removeMessageEventListener(messageHandler);
      };

      this.calculationWebsocket.addMessageEventListener(messageHandler);

      this.state.continue();
    });
  }
}
