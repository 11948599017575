import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { ECommonCommandMessageType } from "@/common/constants";
import { LOGIN_API_REDUCER_PATH } from "@/ui/features/login/store/login/constants";

export function createLoginApi(
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    typeof LOGIN_API_REDUCER_PATH
  >
) {
  return builder.query<void, { username: string; password: string; connection: string }>({
    queryFn: async () => {
      const twoSeconds = 1000 * 2;
      await new Promise((resolve) => setTimeout(resolve, twoSeconds)); // TODO: Remove delay

      // TODO: To integrate with Auth Service
      // const response = await fetch("http://localhost:5000/token/new", {
      //   body: JSON.stringify({
      //     password,
      //     username,
      //   }),
      //   headers: {
      //     "content-type": "application/json",
      //   },
      //   method: "POST",
      // });

      // const token = await response.json();

      // if (response.ok) {
      //   Office.context.ui.messageParent(
      //     JSON.stringify({ data: token, type: ECommonCommandMessageType.CLOSE_DIALOG })
      //   );
      // }

      Office.context.ui.messageParent(JSON.stringify({ data: "", type: ECommonCommandMessageType.CLOSE_DIALOG }));

      return { data: undefined };
    },
  });
}
