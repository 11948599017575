import * as React from "react";
import { EnvironmentManager } from "@/common/utils/environmentManager";
import { Image, makeStyles, tokens } from "@fluentui/react-components";

export default function ValidationResultNoErrors() {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Image className={styles.image} src={`${EnvironmentManager.getVariable("baseUrl")}/images/success-96.png`} />
      <div className={styles.title}>{`Validation successful` /* TODO: Localization */}</div>
      <div className={styles.subtitle}>{`There are no issues found with your data` /* TODO: Localization */}</div>
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalM,
    height: "100%",
    justifyContent: "center",
    padding: tokens.spacingHorizontalL,
    width: "100%",
  },
  image: {
    height: "80px",
    width: "80px",
  },
  subtitle: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
    textAlign: "center",
  },
  title: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
    textAlign: "center",
  },
});
