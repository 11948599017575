import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export function getCurrentDateTimeUTCString() {
  return dayjs().utc().format();
}

export function isValidDate(date: string) {
  return dayjs(date.toString(), "YYYYMMDD", true).isValid();
}

export function isValidYearMonth(date: string) {
  return dayjs(date.toString(), "YYYYMM", true).isValid();
}
