import * as React from "react";
import {
  Badge,
  Button,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogTitle,
  Dropdown,
  Field,
  Image,
  Label,
  Option,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Tag,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { DocumentRegular } from "@fluentui/react-icons";
import { ECalculationType } from "@/common/constants";
import { EnvironmentManager } from "@/common/utils/environmentManager";
import { InputWorksheetDefinition } from "@/common/types";
import {
  useGetTemplateTypesQuery,
  useGetTemplatesQuery,
  useGetWorksheetNamesQuery,
} from "@/ui/features/synchronize/store/synchronize";

export interface SynchronizeTemplateListDialogBodyProps {
  // eslint-disable-next-line no-unused-vars
  onOk: (properties: {
    templateType: ObjectValues<typeof ECalculationType>;
    templatesToAdd: InputWorksheetDefinition[];
    templatesToReplace: InputWorksheetDefinition[];
  }) => void;
  onCancel: () => void;
}

export default function SynchronizeTemplateListDialogBody(props: SynchronizeTemplateListDialogBodyProps) {
  const { onCancel, onOk } = props;

  const styles = useStyles();

  const [templateType, setTemplateType] = React.useState<ObjectValues<typeof ECalculationType> | null>(null);

  const { data: templateTypes = [] } = useGetTemplateTypesQuery();
  const { data: templates = [] } = useGetTemplatesQuery(templateType, {
    skip: templateType === null,
  });
  const { data: worksheetNames = [] } = useGetWorksheetNamesQuery();

  const { worksheetNamesToAdd, worksheetNamesToReplace } = React.useMemo(() => {
    const lowerCasedWorksheetNames = worksheetNames.map((worksheetName) => worksheetName.toLowerCase());
    const worksheetNamesToAdd = templates
      .filter((template) => !lowerCasedWorksheetNames.includes(template.name.toLowerCase()))
      .map((template) => template.name);

    const lowerCasedTemplateNames = templates.map((template) => template.name.toLowerCase());

    const worksheetNamesToReplace = worksheetNames.filter((worksheetName) =>
      lowerCasedTemplateNames.includes(worksheetName.toLowerCase())
    );

    return {
      worksheetNamesToAdd,
      worksheetNamesToReplace,
    };
  }, [worksheetNames, templates]);

  const tableRows = React.useMemo(() => {
    const worksheetsToReplace = worksheetNamesToReplace.map((worksheetName) => {
      return {
        // TODO: Localization
        badgeClassName: styles.replaceBadge,

        name: worksheetName,

        nameLabelClassName: styles.replaceNameLabel,
        tagClassName: styles.replaceTag,
        tagText: "Replace",
      };
    });

    const worksheetsToAdd = worksheetNamesToAdd.map((worksheetName) => {
      return {
        // TODO: Localization
        badgeClassName: styles.addBadge,

        name: worksheetName,

        nameLabelClassName: styles.addNameLabel,
        tagClassName: styles.addTag,
        tagText: "Add",
      };
    });

    return [...worksheetsToReplace, ...worksheetsToAdd].map((worksheet) => {
      const { badgeClassName, name, nameLabelClassName, tagClassName, tagText } = worksheet;
      return (
        <TableRow key={name}>
          <TableCell>
            <TableCellLayout media={<DocumentRegular />}>
              <Label className={nameLabelClassName}>{name}</Label>
            </TableCellLayout>
          </TableCell>
          <TableCell>
            <TableCellLayout>
              <Tag
                className={tagClassName}
                size="small"
                icon={<Badge className={badgeClassName} size="extra-small" />}
                shape="circular"
              >
                {tagText}
              </Tag>
            </TableCellLayout>
          </TableCell>
        </TableRow>
      );
    });
  }, [
    worksheetNamesToReplace,
    worksheetNamesToAdd,
    styles.replaceNameLabel,
    styles.replaceTag,
    styles.replaceBadge,
    styles.addNameLabel,
    styles.addTag,
    styles.addBadge,
  ]);

  return (
    <DialogBody>
      <DialogTitle className={styles.dialogTitle}>
        <Image
          className={styles.dialogTitleImage}
          src={`${EnvironmentManager.getVariable("baseUrl")}/images/sync-32.png`}
        />
        <span>{"Synchronize" /* TODO: Localization */}</span>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Field label={<Label required>{"Template" /* TODO: Localization */}</Label>}>
          <Dropdown
            placeholder={"Select a template" /* TODO: Localization */}
            value={templateType ?? ""}
            onOptionSelect={(_, data) => setTemplateType(data.optionValue as ObjectValues<typeof ECalculationType>)}
          >
            {templateTypes.map((templateType) => (
              <Option key={templateType}>{templateType}</Option>
            ))}
          </Dropdown>
        </Field>
        {templates.length > 0 && (
          <Table size="small" noNativeElements>
            <TableHeader>
              <TableRow>
                <TableHeaderCell key={"name"}>{"Name" /* TODO: Localization */}</TableHeaderCell>
                <TableHeaderCell key={"actionRequired"}>{"Action Required" /* TODO: Localization */}</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody style={{ height: "200px", overflow: "auto" }}>{tableRows}</TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          appearance="primary"
          disabled={templateType === null}
          onClick={() => {
            if (templateType !== null) {
              onOk({
                templateType,
                templatesToAdd: templates.filter((template) => worksheetNamesToAdd.includes(template.name)),
                templatesToReplace: templates.filter((template) => worksheetNamesToReplace.includes(template.name)),
              });
            }
          }}
        >
          {"OK" /* TODO: Localization */}
        </Button>
        <Button appearance="secondary" onClick={onCancel}>
          {"Cancel" /* TODO: Localization */}
        </Button>
      </DialogActions>
    </DialogBody>
  );
}

const useStyles = makeStyles({
  addBadge: { backgroundColor: tokens.colorStatusSuccessBackground3 },
  addNameLabel: { color: tokens.colorStatusSuccessBackground3 },
  addTag: {
    backgroundColor: tokens.colorStatusSuccessBackground1,
    color: tokens.colorStatusSuccessBackground3,
  },
  dialogContent: {
    paddingBottom: tokens.spacingVerticalM,
    paddingTop: tokens.spacingVerticalM,
  },
  dialogTitle: { alignItems: "center", display: "flex", gap: "10px" },
  dialogTitleImage: { height: "24px", width: "24px" },
  extraMessage: {
    color: tokens.colorStatusDangerBackground3,
    fontWeight: "lighter",
    marginTop: tokens.spacingVerticalS,
  },
  replaceBadge: { backgroundColor: tokens.colorPaletteYellowBackground3 },
  replaceNameLabel: { color: tokens.colorPaletteYellowBackground3 },
  replaceTag: {
    backgroundColor: tokens.colorPaletteYellowBackground1,
    color: tokens.colorPaletteYellowBackground3,
  },
});
