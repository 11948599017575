import { AddRoleBasedIpEntryDialog } from "@/ui/features/manage/components/AddRoleBasedIpEntryDialog";
import { TableCellLayout, TableColumnDefinition, createTableColumn } from "@fluentui/react-components";
import IpWhitelistBaseComponent from "@/ui/features/manage/components/IpWhitelistBaseComponent";
import React from "react";

interface RoleBasedIpWhitelistEntry {
  role: string;
  ipAddress: string;
}

export interface RoleBasedIpWhitelistProps {
  enabled: boolean;
  onEnabledSwitch: (enabled: boolean) => void;
}

export default function RoleBasedIpWhitelist(props: RoleBasedIpWhitelistProps) {
  const [isAddRoleBasedIpEntryDialogOpen, setIsAddRoleBasedIpEntryDialogOpen] = React.useState(false);

  const columns: TableColumnDefinition<RoleBasedIpWhitelistEntry>[] = [
    createTableColumn<RoleBasedIpWhitelistEntry>({
      columnId: "Role",
      compare: (a, b) => {
        return a.role.localeCompare(b.role);
      },
      renderCell: (item) => {
        return <TableCellLayout>{item.role}</TableCellLayout>;
      },
      renderHeaderCell: () => {
        return "Role"; // TODO: Localization
      },
    }),
  ];

  const [items, setItems] = React.useState<RoleBasedIpWhitelistEntry[]>([
    {
      ipAddress: "143.255.247.46",
      role: "Administrator",
    },
    {
      ipAddress: "120.140.227.155",
      role: "Power User",
    },
    {
      ipAddress: "52.255.203.109",
      role: "General User",
    },
  ]);

  return (
    <>
      <IpWhitelistBaseComponent
        title={"Role-based IP Whitelist" /* TODO: Localization */}
        subtitle={"Control IP restrictions on selected pre-defined roles" /* TODO: Localization */}
        columns={columns}
        items={items}
        onDeleteConfirm={(itemToDelete) => setItems((prev) => prev.filter((item) => item !== itemToDelete))}
        onAddClick={() => setIsAddRoleBasedIpEntryDialogOpen(true)}
        {...props}
      />
      {isAddRoleBasedIpEntryDialogOpen && (
        <AddRoleBasedIpEntryDialog
          open={isAddRoleBasedIpEntryDialogOpen}
          onOk={(entry) => {
            setItems((prev) => [...prev, entry]);
            setIsAddRoleBasedIpEntryDialogOpen(false);
          }}
          onCancel={() => setIsAddRoleBasedIpEntryDialogOpen(false)}
        />
      )}
    </>
  );
}
