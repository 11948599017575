import { InputWorksheetDefinition } from "@/common/types";
import React from "react";

export function useInputWorksheetsDataStaleCheck(inputWorksheetDefinitions: InputWorksheetDefinition[]) {
  const [isInputWorksheetsDataStale, setIsInputWorksheetsDataStale] = React.useState(false);

  React.useEffect(() => {
    const worksheetIds: string[] = [];

    const handleWorksheetDelete = async (args: Excel.WorksheetDeletedEventArgs) => {
      if (worksheetIds.includes(args.worksheetId)) {
        setIsInputWorksheetsDataStale(true);
      }
    };

    const handleWorksheetDataChange = async () => {
      setIsInputWorksheetsDataStale(true);
    };

    Excel.run(async (context) => {
      for (const { name } of inputWorksheetDefinitions) {
        const worksheet = context.workbook.worksheets.getItemOrNullObject(name);
        // eslint-disable-next-line office-addins/no-context-sync-in-loop
        await context.sync();

        if (!worksheet.isNullObject) {
          worksheet.onChanged.add(handleWorksheetDataChange);
          worksheetIds.push(worksheet.id);
        }
      }

      context.workbook.worksheets.onDeleted.add(handleWorksheetDelete);
    });

    return () => {
      Excel.run(async (context) => {
        for (const { name } of inputWorksheetDefinitions) {
          const worksheet = context.workbook.worksheets.getItemOrNullObject(name);
          // eslint-disable-next-line office-addins/no-context-sync-in-loop
          await context.sync();

          if (!worksheet.isNullObject) {
            worksheet.onChanged.remove(handleWorksheetDataChange);
          }
        }

        context.workbook.worksheets.onDeleted.remove(handleWorksheetDelete);
      });
    };
  }, [inputWorksheetDefinitions]);

  return [isInputWorksheetsDataStale, setIsInputWorksheetsDataStale] as const;
}
