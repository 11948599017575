import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";

export interface DeleteKeyConfirmDialogProps {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}
export function DeleteKeyConfirmDialog(props: DeleteKeyConfirmDialogProps) {
  const { onCancel, onOk, open } = props;

  return (
    <Dialog open={open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{"Confirm delete?" /* TODO: Localization */}</DialogTitle>
          <DialogContent>
            {"This action might cause you to lose access to the application, proceed?" /* TODO: Localization */}
          </DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={onOk}>
              {"OK" /* TODO: Localization */}
            </Button>
            <Button appearance="secondary" onClick={onCancel}>
              {"Cancel" /* TODO: Localization */}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
