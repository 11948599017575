import { AddUserBasedIpEntryDialog } from "@/ui/features/manage/components/AddUserBasedIpEntryDialog";
import { TableCellLayout, TableColumnDefinition, createTableColumn } from "@fluentui/react-components";
import IpWhitelistBaseComponent from "@/ui/features/manage/components/IpWhitelistBaseComponent";
import React from "react";

interface UserBasedIpWhitelistEntry {
  username: string;
  ipAddress: string;
}

export interface UserBasedIpWhitelistProps {
  enabled: boolean;
  onEnabledSwitch: (enabled: boolean) => void;
}

export default function UserBasedIpWhitelist(props: UserBasedIpWhitelistProps) {
  const [isAddUserBasedIpEntryDialogOpen, setIsAddUserBasedIpEntryDialogOpen] = React.useState(false);

  const columns: TableColumnDefinition<UserBasedIpWhitelistEntry>[] = [
    createTableColumn<UserBasedIpWhitelistEntry>({
      columnId: "username",
      compare: (a, b) => {
        return a.username.localeCompare(b.username);
      },
      renderCell: (item) => {
        return <TableCellLayout>{item.username}</TableCellLayout>;
      },
      renderHeaderCell: () => {
        return "Username"; // TODO: Localization
      },
    }),
  ];

  const [items, setItems] = React.useState<UserBasedIpWhitelistEntry[]>([
    {
      ipAddress: "143.255.247.46",
      username: "administrator",
    },
    {
      ipAddress: "120.140.227.155",
      username: "john.doe",
    },
    {
      ipAddress: "52.255.203.109",
      username: "jane.doe",
    },
  ]);

  return (
    <>
      <IpWhitelistBaseComponent
        title={"User-based IP Whitelist" /* TODO: Localization */}
        subtitle={"Control IP restrictions on selected users" /* TODO: Localization */}
        columns={columns}
        items={items}
        onDeleteConfirm={(itemToDelete) => setItems((prev) => prev.filter((item) => item !== itemToDelete))}
        onAddClick={() => setIsAddUserBasedIpEntryDialogOpen(true)}
        {...props}
      />
      {isAddUserBasedIpEntryDialogOpen && (
        <AddUserBasedIpEntryDialog
          open={isAddUserBasedIpEntryDialogOpen}
          onOk={(entry) => {
            setItems((prev) => [...prev, entry]);
            setIsAddUserBasedIpEntryDialogOpen(false);
          }}
          onCancel={() => setIsAddUserBasedIpEntryDialogOpen(false)}
        />
      )}
    </>
  );
}
