import env from "@beam-australia/react-env";

export interface EnvironmentVariables {
  baseUrl: string;
  backendBaseUrl: string;
}

export class EnvironmentManager {
  public static variables: Record<string, any> = {
    backendBaseUrl: "https://localhost:8000",
    baseUrl: "https://localhost:3000",
  };

  private static DATA_TYPES: Record<
    string,
    {
      key: string;
      type: typeof String | typeof Number | typeof Boolean | typeof Array | typeof Object;
    }
  > = {
    backendBaseUrl: { key: "BACKEND_BASE_URL", type: String },
    baseUrl: { key: "BASE_URL", type: String },
  };

  public static initializeEnvironmentVariables(): void {
    for (const key of Object.keys(EnvironmentManager.variables)) {
      const { key: underscoreCasedKey, type: dataType } = EnvironmentManager.DATA_TYPES[key] ?? {};

      if (!underscoreCasedKey || !dataType) continue;

      const environmentVariable = env(underscoreCasedKey);

      if (environmentVariable === undefined || environmentVariable === null) continue;

      if (dataType === Number) {
        const parsedNumber = Number.parseInt(environmentVariable, 10);
        if (!Number.isNaN(parsedNumber)) {
          EnvironmentManager.variables[key] = parsedNumber;
        }
      } else if (dataType === Array) {
        EnvironmentManager.variables[key] = environmentVariable.split(/(?<!\\),/g);
      } else if (dataType === Object) {
        try {
          const parsedJSON = JSON.parse(environmentVariable);

          if (dataType === Object && parsedJSON.constructor === Object) {
            EnvironmentManager.variables[key] = parsedJSON;
          }
        } catch (err: unknown) {
          continue;
        }
      } else if (dataType === Boolean) {
        if (environmentVariable === "true") {
          EnvironmentManager.variables[key] = true;
        } else if (environmentVariable === "false") {
          EnvironmentManager.variables[key] = false;
        }
      } else if (dataType === String) {
        EnvironmentManager.variables[key] = environmentVariable;
      }
    }
  }

  public static getVariable<T extends keyof EnvironmentVariables>(variable: T) {
    return this.variables[variable] as EnvironmentVariables[T];
  }
}
