import * as React from "react";
import { Dropdown, Field, Option } from "@fluentui/react-components";

export interface KeyLengthDropdownFieldProps {
  keyLength: number;
  onChange: (keyLength: number) => void;
}

export default function KeyLengthDropdownField(props: KeyLengthDropdownFieldProps) {
  const { keyLength, onChange } = props;

  return (
    <Field label={"Key Length" /* TODO: Localization */} validationState="none">
      <Dropdown
        placeholder={"Select a key length" /* TODO: Localization */}
        value={`${keyLength}`}
        onOptionSelect={(_, { optionValue }) => {
          if (!optionValue) {
            return;
          }

          const parsedKeyLength = Number.parseInt(optionValue);
          if (Number.isInteger(parsedKeyLength)) {
            onChange(parsedKeyLength);
          }
        }}
      >
        {KEY_LENGTHS.map(({ label, value }) => (
          <Option key={keyLength} text={label}>
            {value}
          </Option>
        ))}
      </Dropdown>
    </Field>
  );
}

const KEY_LENGTHS = [
  { label: "512", value: 512 },
  { label: "1024", value: 1024 },
  { label: "2048", value: 2048 },
  { label: "4096", value: 4096 },
];

KeyLengthDropdownField.DEFAULT_KEY_LENGTH = KEY_LENGTHS[2].value;
