import * as React from "react";
import { Button, Field, Input, Label, makeStyles, tokens } from "@fluentui/react-components";
import { PasswordRegular } from "@fluentui/react-icons";

export interface ResetPasswordFormProps {
  onResetPassword: () => void;
}
export default function ResetPasswordForm(props: ResetPasswordFormProps) {
  const { onResetPassword } = props;

  const styles = useStyles();

  const [newPassword, setNewPassword] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");

  const resetPassword = () => {
    if (newPassword && confirmNewPassword) {
      onResetPassword();
    }
  };

  const formFieldsOnKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      resetPassword();
    }
  };

  return (
    <div className={styles.background}>
      <div className={styles.headerContainer}>
        <Label className={styles.headerLabel}>{"Reset your password" /* TODO: Localization */}</Label>
      </div>
      <div className={styles.subHeaderContainer}>
        <Label className={styles.subHeaderLabel}>
          {"Enter a new password below to reset your password" /* TODO: Localization */}
        </Label>
      </div>
      <div>
        <Field
          label={
            <Label className={styles.passwordFieldLabel} required>
              {"New password" /* TODO: Localization */}
            </Label>
          }
        >
          <Input
            type="password"
            contentBefore={<PasswordRegular className={styles.passwordFieldIcon} />}
            placeholder={"Enter new password" /* TODO: Localization */}
            onChange={(event) => setNewPassword(event.target.value)}
            onKeyDown={formFieldsOnKeyDown}
          />
        </Field>
        <Field
          label={
            <Label className={styles.passwordFieldLabel} required>
              {"Confirm new password" /* TODO: Localization */}
            </Label>
          }
        >
          <Input
            type="password"
            contentBefore={<PasswordRegular className={styles.passwordFieldIcon} />}
            placeholder={"Confirm new password" /* TODO: Localization */}
            onChange={(event) => setConfirmNewPassword(event.target.value)}
            onKeyDown={formFieldsOnKeyDown}
          />
        </Field>
      </div>
      <div>
        <Button
          className={styles.resetPasswordButton}
          appearance="primary"
          disabled={!(newPassword && confirmNewPassword && newPassword === confirmNewPassword)}
          onClick={resetPassword}
        >
          {"Reset Password" /* TODO: Localization */}
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  background: {
    background: tokens.colorNeutralForegroundInverted,
    borderRadius: tokens.borderRadiusXLarge,
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
    display: "flex",
    flexDirection: "column",
    height: "300px",
    padding: tokens.spacingVerticalXXXL,
    rowGap: tokens.spacingVerticalL,
    width: "500px",
  },
  headerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  headerLabel: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
  },
  passwordFieldIcon: { paddingRight: tokens.spacingHorizontalSNudge },
  passwordFieldLabel: {
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    textTransform: "uppercase",
  },
  passwordInputSuffix: {
    color: tokens.colorBrandStroke1,
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    minWidth: "unset",
    padding: "unset",
    textTransform: "uppercase",
  },
  resetPasswordButton: {
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightMedium,
    letterSpacing: "0.75px",
    textTransform: "uppercase",
    width: "100%",
  },
  subHeaderContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  subHeaderLabel: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
  },
});
