import * as React from "react";
import { RouteProps } from "react-router-dom";
import Apps from "@/ui/features/manage/views/Apps";
import Audit from "@/ui/features/manage/views/Audit";
import Dashboard from "@/ui/features/manage/views/Dashboard";
import IpWhitelist from "@/ui/features/manage/views/security/IpWhitelist";
import Keys from "@/ui/features/manage/views/security/Keys";
import Settings from "@/ui/features/manage/views/Settings";
import Themes from "@/ui/features/manage/views/UserSettings/Themes";
import Usage from "@/ui/features/manage/views/Usage";
import Users from "@/ui/features/manage/views/Users";

const ROUTES: RouteProps[] = [
  {
    element: <Dashboard />,
    path: "/manage",
  },
  {
    element: <IpWhitelist />,
    path: "/manage/security/ip-whitelist",
  },
  {
    element: <Users />,
    path: "/manage/users",
  },
  {
    element: <Apps />,
    path: "/manage/apps",
  },
  {
    element: <Usage />,
    path: "/manage/usage",
  },
  {
    element: <Audit />,
    path: "/manage/audit",
  },
  {
    element: <Settings />,
    path: "/manage/settings",
  },
  {
    element: <Keys />,
    path: "/manage/user-settings/keys",
  },
  {
    element: <Themes />,
    path: "/manage/user-settings/themes",
  },
];

export default ROUTES;
