import PageView from "@/ui/features/manage";
import React from "react";

export default function Users() {
  return (
    <PageView>
      <></>
    </PageView>
  );
}
