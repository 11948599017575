import { SYNCHRONIZE_API_REDUCER_PATH } from "@/ui/features/synchronize/store/synchronize/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createGetTemplateTypesApi } from "@/ui/features/synchronize/store/synchronize/apis/getTemplateTypes";
import { createGetTemplatesApi } from "@/ui/features/synchronize/store/synchronize/apis/getTemplates";
import { createGetWorksheetNamesApi } from "@/ui/features/synchronize/store/synchronize/apis/getWorksheetNames";
import { createSynchronizeWorkbookWithTemplates } from "@/ui/features/synchronize/store/synchronize/apis/synchronizeWorkbookWithTemplates";

export const synchronizeApiSlice = createApi({
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getTemplateTypes: createGetTemplateTypesApi(builder),
    getTemplates: createGetTemplatesApi(builder),
    getWorksheetNames: createGetWorksheetNamesApi(builder),
    synchronizeWorkbookWithTemplates: createSynchronizeWorkbookWithTemplates(builder),
  }),
  reducerPath: SYNCHRONIZE_API_REDUCER_PATH,
});

export const {
  useGetTemplateTypesQuery,
  useGetTemplatesQuery,
  useGetWorksheetNamesQuery,
  useSynchronizeWorkbookWithTemplatesQuery,
} = synchronizeApiSlice;
