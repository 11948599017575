import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { CalculationHttpClient } from "@/common/api/calculationHttpClient/calculationHttpClient";
import { ECalculationType } from "@/common/constants";
import { InputWorksheetDefinition } from "@/common/types";
import { SYNCHRONIZE_API_REDUCER_PATH } from "@/ui/features/synchronize/store/synchronize/constants";

export function createGetTemplatesApi(
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    typeof SYNCHRONIZE_API_REDUCER_PATH
  >
) {
  return builder.query<InputWorksheetDefinition[], ObjectValues<typeof ECalculationType> | null>({
    queryFn: async (calculationType) => {
      if (calculationType !== null) {
        const inputWorksheetDefinitions = await CalculationHttpClient.getInputWorksheetDefinitions(calculationType);
        return { data: inputWorksheetDefinitions };
      } else {
        return { data: [] };
      }
    },
  });
}
