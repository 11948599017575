import * as React from "react";
import { ECalculationType, ECommonCommandMessageType } from "@/common/constants";
import { useLazyCalculateQuery } from "@/ui/features/calculate/store/calculate";
import CalculateConfirmDialog from "@/ui/features/calculate/components/CalculateConfirmDialog";
import CalculatingDialog from "@/ui/features/calculate/components/CalculatingDialog";
import CalculationFailedDialog from "@/ui/features/calculate/components/CalculationFailedDialog";

export interface SupportedCalculationDialogProps {
  open: boolean;
  calculationType: ObjectValues<typeof ECalculationType>;
}

export default function SupportedCalculationDialog(props: SupportedCalculationDialogProps) {
  const { calculationType, open } = props;

  const [calculate, { isError: isCalculationFailed, isFetching: isCalculating }] = useLazyCalculateQuery();

  const startCalculation = () => calculate(calculationType);
  const closeDialog = () =>
    Office.context.ui.messageParent(JSON.stringify({ type: ECommonCommandMessageType.CLOSE_DIALOG }));

  if (isCalculating) {
    return <CalculatingDialog open={open} />;
  } else if (isCalculationFailed) {
    return <CalculationFailedDialog open={open} onRetry={startCalculation} onCancel={closeDialog} />;
  } else {
    return (
      <CalculateConfirmDialog
        open={open}
        calculationType={calculationType}
        onOk={startCalculation}
        onCancel={closeDialog}
      />
    );
  }
}
