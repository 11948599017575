import * as React from "react";
import { EnvironmentManager } from "@/common/utils/environmentManager";
import { Image, Text, makeStyles, tokens } from "@fluentui/react-components";

export default function NoResultsFound() {
  const styles = useStyles();

  return (
    <div className={styles.noResultsFoundContainer}>
      <Image
        className={styles.noResultsFoundImage}
        src={`${EnvironmentManager.getVariable("baseUrl")}/images/search-96.png`}
      />
      <Text className={styles.noResultsFoundTitle}>{"No results found" /* TODO: Localization */}</Text>
      <Text className={styles.noResultsFoundSubtitle}>{
        `There are no results matching your search criteria` /* TODO: Localization */
      }</Text>
    </div>
  );
}

const useStyles = makeStyles({
  noResultsFoundContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalM,
    height: "calc(100% - 33px)",
    justifyContent: "center",
  },
  noResultsFoundImage: {
    height: "48px",
    width: "48px",
  },
  noResultsFoundSubtitle: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightRegular,
    textAlign: "center",
  },
  noResultsFoundTitle: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
    textAlign: "center",
  },
});
