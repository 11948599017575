import * as React from "react";
import {
  Avatar,
  Button,
  Image,
  Link,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuGroupHeader,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  BookInformationRegular,
  BugRegular,
  DocumentOnePageMultipleRegular,
  EyeRegular,
  KeyMultipleRegular,
  PaintBrushRegular,
  PersonInfoRegular,
  PersonSettingsRegular,
  PersonSupportRegular,
  PhoneLockRegular,
  QuestionCircleRegular,
} from "@fluentui/react-icons";
import { EnvironmentManager } from "@/common/utils/environmentManager";
import { useNavigate } from "react-router-dom";

export default function TopNavigation() {
  const styles = useStyles();

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <Link
        onClick={() => {
          window.open("https://www.numtech.com/ntcalcxl");
        }}
      >
        <Image
          src={`${EnvironmentManager.getVariable("baseUrl")}/images/logo-full-100x300.png`}
          width={150}
          height={50}
        />
      </Link>
      <div className={styles.userActionsContainer}>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <Button appearance="subtle" style={{ height: "32px", minWidth: "32px", padding: "0px" }}>
              <QuestionCircleRegular fontSize={"16px"} />
            </Button>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              <MenuGroup>
                <MenuGroupHeader>{"Guides" /* TODO: Localization */}</MenuGroupHeader>
                <MenuItem icon={<BookInformationRegular fontSize={"16px"} />}>
                  {"Get started" /* TODO: Localization */}
                </MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                <MenuGroupHeader>{"Support" /* TODO: Localization */}</MenuGroupHeader>
                <MenuItem icon={<BugRegular fontSize={"16px"} />}>{"Report a bug" /* TODO: Localization */}</MenuItem>
                <MenuItem icon={<PersonSupportRegular fontSize={"16px"} />}>
                  {"Contact support" /* TODO: Localization */}
                </MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                <MenuGroupHeader>{"Legal" /* TODO: Localization */}</MenuGroupHeader>
                <MenuItem icon={<EyeRegular fontSize={"16px"} />}>{"Privacy policy" /* TODO: Localization */}</MenuItem>
                <MenuItem icon={<DocumentOnePageMultipleRegular fontSize={"16px"} />}>
                  {"Terms & conditions" /* TODO: Localization */}
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </MenuPopover>
        </Menu>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <Button appearance="subtle" style={{ height: "32px", minWidth: "32px", padding: "0px" }}>
              <PersonSettingsRegular fontSize={"16px"} />
            </Button>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              <MenuGroup>
                <MenuGroupHeader>{"Personal" /* TODO: Localization */}</MenuGroupHeader>
                <MenuItem icon={<PersonInfoRegular fontSize={"16px"} />}>{"Profile" /* TODO: Localization */}</MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                <MenuGroupHeader>{"Preferences" /* TODO: Localization */}</MenuGroupHeader>
                <MenuItem
                  icon={<PaintBrushRegular fontSize={"16px"} />}
                  onClick={() => {
                    navigate("/manage/user-settings/themes");
                  }}
                >
                  {"Themes" /* TODO: Localization */}
                </MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                <MenuGroupHeader>{"Security" /* TODO: Localization */}</MenuGroupHeader>
                <MenuItem
                  icon={<KeyMultipleRegular fontSize={"16px"} />}
                  onClick={() => {
                    navigate("/manage/user-settings/keys");
                  }}
                >
                  {"RSA Keys" /* TODO: Localization */}
                </MenuItem>
                <MenuItem icon={<PhoneLockRegular fontSize={"16px"} />}>
                  {"2FA Authentication" /* TODO: Localization */}
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </MenuPopover>
        </Menu>
        <Avatar color="colorful" name="John Doe" />
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    alignItems: "center",
    backgroundColor: tokens.colorNeutralBackground1,
    display: "flex",
    ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStencil1Alpha),
    ...shorthands.padding(tokens.spacingHorizontalS, tokens.spacingHorizontalM),
  },
  userActionsContainer: {
    columnGap: tokens.spacingHorizontalM,
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
});
