import OrganizationBasedIpWhitelist from "@/ui/features/manage/components/OrganizationBasedIpWhitelist";
import PageView from "@/ui/features/manage";
import React from "react";
import RoleBasedIpWhitelist from "@/ui/features/manage/components/RoleBasedIpWhitelist";
import UserBasedIpWhitelist from "@/ui/features/manage/components/UserBasedIpWhitelist";

export default function IpWhitelist() {
  const [isOrganizationBasedIpWhitelistEnabled, setIsOrganizationBasedIpWhitelistEnabled] = React.useState(false);
  const [isRoleBasedIpWhitelistEnabled, setIsRoleBasedIpWhitelistEnabled] = React.useState(false);
  const [isUserBasedIpWhitelistEnabled, setIsUserBasedIpWhitelistEnabled] = React.useState(false);

  return (
    <PageView>
      <OrganizationBasedIpWhitelist
        enabled={isOrganizationBasedIpWhitelistEnabled}
        onEnabledSwitch={setIsOrganizationBasedIpWhitelistEnabled}
      />
      <RoleBasedIpWhitelist
        enabled={isRoleBasedIpWhitelistEnabled}
        onEnabledSwitch={setIsRoleBasedIpWhitelistEnabled}
      />
      <UserBasedIpWhitelist
        enabled={isUserBasedIpWhitelistEnabled}
        onEnabledSwitch={setIsUserBasedIpWhitelistEnabled}
      />
    </PageView>
  );
}
