import { CalculationWebsocket } from "@/common/api/calculationWebsocket/calculationWebsocket";

export class CalculationReceivingLogs {
  private static WS_CALCULATION_COMPLETED_BOUNDARY = "WS_CALCULATION_COMPLETED";
  private static WS_CALCULATION_FAILED_BOUNDARY = "WS_CALCULATION_FAILED";

  constructor(private calculationWebsocket: CalculationWebsocket) {}

  public async receiveLogs() {
    return Promise.race([this.listenToConnectionLoss(), this.listenToIncomingMessage()]);
  }

  private async listenToConnectionLoss(): Promise<never> {
    const threeHundredMs = 300;

    while (this.calculationWebsocket.isConnected()) {
      await new Promise((resolve) => setTimeout(resolve, threeHundredMs));
    }

    throw new Error("Lost connection while receiving logs.");
  }

  private async listenToIncomingMessage() {
    return new Promise<void>((resolve, reject) => {
      const messageHandler = async (messageEvent: MessageEvent<any>) => {
        const { data } = messageEvent;

        if (typeof data !== "string") {
          reject(new Error("Invalid data received, expected string."));
        }

        if (data === CalculationReceivingLogs.WS_CALCULATION_COMPLETED_BOUNDARY) {
          resolve();
        } else if (data === CalculationReceivingLogs.WS_CALCULATION_FAILED_BOUNDARY) {
          resolve();
        }

        this.calculationWebsocket.removeMessageEventListener(messageHandler);
      };

      this.calculationWebsocket.addMessageEventListener(messageHandler);
    });
  }
}
