import { ESynchronizingAction } from "@/common/constants";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface SynchronizeState {
  currentSynchronizingAction: ObjectValues<typeof ESynchronizingAction>;
  noSynchronized: number;
  totalNoToSynchronize: number;
}

const initialState: SynchronizeState = {
  currentSynchronizingAction: ESynchronizingAction.ADD,
  noSynchronized: 0,
  totalNoToSynchronize: 0,
};

export const synchronizeSlice = createSlice({
  initialState,
  name: "synchronize",
  reducers: {
    changeCurrentSynchronizingAction: (
      state,
      action: PayloadAction<SynchronizeState["currentSynchronizingAction"]>
    ) => {
      state.currentSynchronizingAction = action.payload;
    },
    changeNoSynchronized: (state, action: PayloadAction<SynchronizeState["noSynchronized"]>) => {
      state.noSynchronized = action.payload;
    },
    changeTotalNoToSynchronize: (state, action: PayloadAction<SynchronizeState["totalNoToSynchronize"]>) => {
      state.totalNoToSynchronize = action.payload;
    },
  },
});

export const { changeCurrentSynchronizingAction, changeNoSynchronized, changeTotalNoToSynchronize } =
  synchronizeSlice.actions;

export default synchronizeSlice.reducer;
